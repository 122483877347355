@import '../../../styles/--scss-vars.scss';

.ff-dropdown-input-wrapper {
  width: 100%;
  position: relative;
  height: fit-content;
  display: flex;
  align-items: baseline;
  @include transition(border-color #{','} color);
  border-bottom: 1px solid var(--input-border-default-color);
  border-radius: 0;
  @include body-text();
  -webkit-font-smoothing: antialiased;
  flex-flow: wrap;
  padding-top: calc(var(--subdiv) * 5);
  > p {
    margin-right: calc(var(--subdiv) * 3);
    margin-bottom: calc(var(--subdiv) * 2);
    // margin-top: var(--subdiv);
    display: flex;
    align-items: center;
    background-color: $color-f5-a;
    border-radius: var(--radius3);
    // padding: calc(var(--subdiv) * 0.5) calc(var(--subdiv) * 2);
    padding: 0 calc(var(--subdiv) * 2);
    color: $color-f0;
    &.disabled {
      span {
        pointer-events: none;
      }
    }

    span {
      margin-left: calc(var(--subdiv) * 2);
      color: $color-f0;
      font: 600 var(--font-size5) / 1.25 'Lato', sans-serif, open-sans;
      cursor: pointer;
    }
  }
  > .list {
    width: 100%;
    max-height: calc(80vh - (var(--baseline) * 12) - var(--floating-message-height) * 1.5);
    @include position(absolute, unset, unset, calc(var(--subdiv) * -2), 0);
    transform: translateY(100%);
    border-radius: 20px;
    padding: calc(var(--subdiv) * 3) 0;
    opacity: 0;
    pointer-events: none;
    @include transition(opacity, $time4);
    z-index: 100;
    overflow-y: scroll;
    background: $color-w0;
    box-shadow: 0 var(--subdiv) calc(var(--subdiv) * 5) var(--subdiv) rgba(1, 14, 40, 0.04);

    &::-webkit-scrollbar {
      display: block;
      width: 3px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-track-piece:start {
      margin-top: calc(var(--baseline) * 0.6);
    }
    &::-webkit-scrollbar-track-piece:end {
      margin-bottom: var(--baseline);
    }

    > li {
      min-height: calc(var(--baseline) + var(--subdiv) * 5);
      width: calc(100% - var(--subdiv) * 10);
      display: flex;
      align-items: center;
      cursor: pointer;
      position: relative;
      padding: calc(var(--subdiv) * 2) calc(var(--subdiv) * 4);
      border-radius: 10px;
      background-color: rgba(255, 255, 255, 0);
      @include transition(background-color, $time4);
      margin: 0 auto;

      &.empty-disabled {
        display: none;
        pointer-events: none;
        &:hover {
          background: none;
        }
        p {
          height: 100%;
          display: flex;
          align-items: center;
          span {
            display: flex;
            align-items: center;
            color: $color-d2;
          }
        }
      }
      &.empty {
        display: block;
        pointer-events: none;
        &:hover {
          background: none;
        }
        p {
          height: 100%;
          display: flex;
          align-items: center;
          color: $color-d2;
          span {
            display: flex;
            align-items: center;
            color: $color-d2;
          }
        }
      }

      &.option-group-title {
        background: $color-d3-b;
        cursor: unset;
        &:hover {
          background: $color-d3-b;
          cursor: unset;
        }
        > p {
          text-transform: uppercase;
          font-weight: bold;
          padding-left: 0px;
          > span {
            height: 100%;
            color: $color-b0;
          }
        }
      }

      > p {
        width: 100%;
        @include body-text();
        color: $color-d0;
        @include transition(color, $time4);
        user-select: none;
        pointer-events: none;
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;

        > span {
          height: 100%;
          color: $color-f0;
        }
      }
      &:hover {
        background-color: $color-f5-a;
      }
      &.active {
        > p {
          color: $color-f0;
        }
      }
    }
    &.top {
      transform: translateY(-17%);
    }
    // @media only screen and (max-width: $brake2-5) {
    //   max-height: calc(65vh - var(--baseline) * 14);
    // }
    @media only screen and (max-width: $brake4-1) {
      max-height: calc(65vh - var(--baseline) * 4);
    }
  }

  svg:first-of-type:not(.arrow) {
    position: absolute;
    right: calc(var(--subdiv) * 5);
    bottom: var(--subdiv);
  }

  > .arrow {
    width: calc(var(--subdiv) * 3);
    display: block;
    @include position(absolute, unset, 0, 0, unset);
    margin-bottom: calc(var(--subdiv) * 3);
    transform: rotate(0deg);
    @include transition(transform, $time4);

    > path {
      stroke: $color-d0;
    }
  }

  > .input-wrapper {
    flex-grow: 1;
    display: flex;
    align-items: center;
    position: relative;
    width: var(--subdiv);
    height: fit-content;
    padding: 0 calc(var(--subdiv) * 3 + 1px) calc(var(--subdiv) * 2 + 1px) 0;
    margin-right: calc(var(--subdiv) * 1);
    margin-top: var(--subdiv);

    > input {
      position: relative;
      -webkit-font-smoothing: antialiased;
      @include transition(border-color #{','} color);
      @include body-text();
      text-align: left;
      color: $color-d0;
      width: 100%;
      // padding: calc(var(--subdiv) * 0.5) calc(var(--subdiv) * 2);
      background: rgba(255, 255, 255, 0);
      // border-bottom: 1px solid var(--input-border-default-color);
      border-radius: 0;
    }

    &:hover {
      > input {
        border-color: var(--input-border-hover-color);
      }
    }
  }

  &[is-error='true'] {
    color: var(--input-border-error-color);
    border-bottom-color: var(--input-text-error-color);
  }

  > span {
    width: 100%;
    opacity: 1;
    top: 0;
    left: 0;
    transform: translateY(calc(100% + var(--subdiv) * 3 - 1px));
    @include transition(transform #{','} opacity);
    @include subtitle-1();
    line-height: var(--font-size3);
    color: var(--input-placeholder-span-color);
    display: block;
    position: absolute;
    pointer-events: none;
    user-select: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.opened {
    > .input-wrapper {
      > input {
        border-color: var(--input-border-focus-color);
      }
      > .arrow {
        transform: rotate(180deg);
      }
    }
    > .list {
      opacity: 1;
      pointer-events: unset;
    }
  }
  &.opened,
  &.filled {
    > span {
      transform: translateY(0px);
    }
  }

  &.disabled {
    pointer-events: auto;
    > .input-wrapper {
      // display: none;
      cursor: not-allowed;
      > input {
        color: $color-d2;
        cursor: not-allowed;
      }
    }
    > .placeholder {
      cursor: not-allowed;
    }
  }

  @media only screen and (max-width: $brake2-5) {
    > .list {
      background-color: $color-w0 !important;
    }
  }

  @media only screen and (max-width: $brake4-1) {
    > .list {
      width: calc(100% + var(--side-margins) * 2) !important;
      left: calc(var(--side-margins) * -1);

      > li {
        width: calc(100% - var(--side-margins) * 2) !important;
        padding: 0 var(--side-margins);
        border-radius: 0;
      }
    }
  }
}

.plain-dropdown {
  ul {
    flex-direction: column;
  }
}
