@import '../--scss-vars.scss';
.dynamic-table {
  --shift-spacing: calc(var(--subdiv) * 7);
  @include text0();
  &.table-responsive {
    overflow: hidden;
    overflow-x: visible;
    width: 100%;
    &::-webkit-scrollbar {
      width: 0px;
      height: 5px; /* Remove scrollbar space */
      // background: transparent; /* Optional: just make scrollbar invisible */
    }
  }
  .no-rows {
    width: 100%;
    text-align: center;
  }
  table {
    width: 100%;
    border-spacing: 0;
    th {
      @include subtitle-1();
    }
    td,
    th {
      vertical-align: middle;
      padding: calc(var(--subdiv) * 2) var(--shift-spacing);
      text-align: left;
    }
    .table-text {
      text-align: left;
      min-width: 130px;
      &.full-width {
        width: 100%;
      }
    }
    thead > tr th:first-child,
    .sticky-col:first-child {
      position: -webkit-sticky;
      position: sticky;
      left: 0;
      z-index: 2;
    }
    thead > tr {
      th {
        background: $color-w0;
        color: $color-d2;
        text-transform: uppercase;
        letter-spacing: 0.1em;
      }
      &:last-child {
        th {
          border-bottom: 1px solid $color-f4;
        }
      }
    }
    .rows,
    .sub-rows {
      td.sticky-col {
        font: 400 var(--font-size3) / 1.66 'Lato', sans-serif, open-sans;
        letter-spacing: -0.03em;
      }
      &:hover {
        td.sticky-col {
          background: $color-f5;
        }
      }
      &:last-child {
        td {
          border-bottom: 1px solid $color-f4;
        }
      }
    }
    .rows {
      td.sticky-col {
        background: $color-f5;
      }
    }
    .sub-rows {
      td:not(.sticky-col:first-child) {
        color: $color-d2;
      }
      .sticky-col {
        background: $color-w0;
      }
    }
  }

  .buttonsContent {
    padding-top: 20px;
    button {
      margin-left: 10px;
    }
  }
}
