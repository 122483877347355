@import '../--scss-vars.scss';

.gallery {
  &.column-3 {
    &:not(.type-2) {
      @media screen and (min-width: $brake2-4 + 1px) {
        @include gallery(3, var(--gutter));
      }
      @media screen and (max-width: $brake2-4) and (min-width: $brake4 + 1px) {
        @include gallery(2, calc(var(--subdiv) * 4));
      }
      @media screen and (max-width: $brake4) {
        @include gallery(1, calc(var(--subdiv) * 3));
        margin: 0 auto;
      }
    }
  }
  &.type-2 {
    &.column-3 {
      @media screen and (min-width: $brake2-4 + 1px) {
        @include gallery(3, var(--gutter), var(--gutter), 2);
      }
      @media screen and (max-width: $brake2-4) and (min-width: $brake4 + 1px) {
        @include gallery(
          2,
          calc(var(--subdiv) * 4),
          calc(var(--subdiv) * 4),
          2
        );
      }
      @media screen and (max-width: $brake4) {
        @include gallery(
          1,
          calc(var(--subdiv) * 3),
          calc(var(--subdiv) * 3),
          2
        );
        margin: 0 auto;
      }
    }
  }
  &.column-2 {
    &:not(.type-2) {
      @media screen and (min-width: $brake2-4 + 1px) {
        @include gallery(2, var(--gutter));
      }
      @media screen and (max-width: $brake2-4) and (min-width: $brake4 + 1px) {
        @include gallery(2, calc(var(--subdiv) * 4));
      }
      @media screen and (max-width: $brake4) {
        @include gallery(1, calc(var(--subdiv) * 3));
        margin: 0 auto;
      }
    }
  }
  &.type-2 {
    &.column-3 {
      @media screen and (min-width: $brake2-4 + 1px) {
        @include gallery(2, var(--gutter), var(--gutter), 2);
      }
      @media screen and (max-width: $brake2-4) and (min-width: $brake4 + 1px) {
        @include gallery(
          2,
          calc(var(--subdiv) * 4),
          calc(var(--subdiv) * 4),
          2
        );
      }
      @media screen and (max-width: $brake4) {
        @include gallery(
          1,
          calc(var(--subdiv) * 3),
          calc(var(--subdiv) * 3),
          2
        );
        margin: 0 auto;
      }
    }
  }
}
