@import '../../../styles/--scss-vars.scss';
.edit-logs {
  .time-entries-table {
    th,
    td {
      width: 105px;
      text-align: center;
      vertical-align: middle !important;
    }
  }

  .edit-logs-table {
    border: 1px solid $color-f4;
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
    white-space: normal;
    th,
    td {
      border: 1px solid $color-f4;
      padding: calc(var(--subdiv) * 2.5);
    }
    .data-label {
      width: 135px;
      min-width: 135px;
    }

    .data-value {
      min-width: 100px;
    }
    .td {
      min-width: 120px;
    }
  }

  .overflow-filter {
    .rt-td {
      .table-responsive {
        width: 100%;
        display: block;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        white-space: normal;
        &::-webkit-scrollbar {
          width: 0px;
          height: 5px;
          display: block;
        }
      }
    }
  }
}
