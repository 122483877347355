@import '../../../styles/--scss-vars.scss';

.pr-expand-collapse {
  width: 100%;

  > .header {
    height: var(--baseline);
    width: 100%;
    margin-bottom: calc(var(--subdiv) * 4);
    display: flex;
    align-items: center;
    position: relative;
    padding-left: calc(var(--subdiv) * 8);

    > .indicator {
      height: calc(var(--subdiv) * 4 + 1px);
      width: calc(var(--subdiv) * 4 + 1px);
      @include position(absolute, unset, unset, unset, 0);
      transform: translateX(0);
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      &:before {
        content: '';
        height: 1px;
        width: 100%;
        display: block;
        background-color: $color-d0;
        position: absolute;
        pointer-events: none;
      }
      &:after {
        content: '';
        height: 100%;
        width: 1px;
        display: block;
        background-color: $color-d0;
        position: absolute;
        pointer-events: none;
        transform: scaleY(0);
        @include transition(transform, $time3);
      }
    }
    > p {
      width: fit-content;
      @include subtitle-0();
      color: $color-d0;
      user-select: none;
      cursor: pointer;
    }
    > .counter {
      @include subtitle-0();
      color: $color-d3;
      margin-left: calc(var(--subdiv) * 2);
      display: none;
    }
    > .pr-counter-button-wrapper {
      margin-left: auto;
      display: none;
      transform: translateX(calc(100% + var(--side-margins)));
      @include transition(transform, $time5);

      &.visible {
        transform: translateX(0);
      }
    }
  }
  > .content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: var(--popup-footer-spacer);
  }
  &.closed {
    > .header {
      > .indicator {
        &:after {
          transform: scaleX(1);
        }
      }
    }
    > .content {
      display: none !important;
    }
  }
  &.inactive {
    pointer-events: none;

    > .header {
      > .indicator {
        &:before,
        &:after {
          background-color: $color-d4-a;
        }
      }
      > p {
        color: $color-d4-a;
      }
    }
    > .content {
      display: none !important;
    }
  }
  &.type-1 {
    > .content {
      > .column {
        height: fit-content;
        position: relative;

        > .arrow {
          display: none;
        }
        > .dropdown {
          width: calc(100% - var(--subdiv) * -10);
          @include position(absolute, unset, unset, calc(var(--subdiv) * -2), 0);
          transform: translateY(100%);
          background-color: $color-w0;
          border-radius: var(--radius6);
          padding: var(--baseline) calc(var(--subdiv) * 5);
          box-shadow: $darker-sh;
          opacity: 0;
          pointer-events: none;
          @include transition(opacity, $time4);
          z-index: 100;

          &:before {
            content: '';
            height: calc(var(--subdiv) * 2);
            width: 100%;
            display: block;
            @include position(absolute, 0, unset, unset, 0);
            transform: translateY(-100%);
          }
          > li {
            width: 100%;
            display: flex;
            align-items: center;
            cursor: pointer;

            &:not(:first-child) {
              margin-top: var(--baseline);
            }
            > .pr-checkbox-wrapper {
              flex-shrink: 0;
            }
            > p {
              @include body-text();
              color: $color-d0;
              user-select: none;
              pointer-events: none;
              margin-left: calc(var(--subdiv) * 3);
            }
          }
        }
        &.active {
          &:hover {
            > .dropdown {
              opacity: 1;
              pointer-events: unset;
            }
          }
        }
      }
    }
  }
  &.type-3 {
    > .content {
      flex-direction: column;

      > .header {
        width: 100%;
        display: flex;

        > .pr-location-input {
          width: 100%;
          margin-right: calc(var(--baseline) * 4 + var(--subdiv) * 2);
        }
        > .controls {
          display: flex;

          > .options-wrapper {
            margin-left: calc(var(--baseline) * 3);
            margin-right: calc(var(--subdiv) * 5);
            > .title {
              @include subtitle-1();
              color: $color-d2;
              user-select: none;
              pointer-events: none;
              white-space: nowrap;
              margin-bottom: calc(var(--subdiv) * 2);
            }
            > div {
              display: flex;
            }

            > .options {
              margin-top: calc(var(--subdiv) * 3);

              > .option {
                display: flex;
                align-items: center;

                > .pr-toggle-wrapper {
                  flex-shrink: 0;
                }
                > p {
                  @include text0();
                  user-select: none;
                  white-space: nowrap;
                  margin-left: calc(var(--subdiv) * 2);
                  cursor: pointer;
                }
                &:not(:first-child) {
                  margin-left: var(--baseline);
                }
              }
            }
          }
        }
      }
    }
  }
  &.type-5 {
    > .content {
      align-items: center;

      > .column {
        height: fit-content;
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: var(--subdiv) 0;
        opacity: 1;
        pointer-events: unset;
        @include transition(opacity, $time3);

        > p {
          @include text0();
          user-select: none;
          pointer-events: none;
          margin-left: calc(var(--subdiv) * 2);
        }
        > div {
          flex-shrink: 0;
        }
        > .pr-data-input-2-wrapper {
          width: 100%;
          margin-top: calc(var(--subdiv) * -3);
        }
        &.toggle {
          border-right: 1px solid $color-d4-a;
          box-sizing: border-box;
        }
        &.disabled {
          opacity: 0;
          pointer-events: none;
        }
      }
    }
  }
  &.type-8 {
    height: 100%;

    > .content {
      height: 100%;

      > .cards {
        width: 100%;

        > .pr-experience-card-wrapper {
          &:not(:first-child) {
            margin-top: calc(var(--subdiv) * 3);
          }
        }
        > .spacer {
          // added for one bottom spacing in desktop
          margin-bottom: var(--side-margins);
        }
      }
      > .indicator {
        display: none;
      }
    }
  }
  &.type-10 {
    > .content {
      > .masonry-item {
        width: 100%;
        margin-bottom: calc(var(--baseline) * 0.5);
        display: inline-flex;
        cursor: pointer;

        > .pr-checkbox-wrapper {
          flex-shrink: 0;
          margin-top: calc(var(--subdiv) + 4px);
        }
        > p {
          @include text0();
          color: $color-d0;
          margin-left: calc(var(--subdiv) * 3);
          user-select: none;
          pointer-events: none;
          margin-top: 2px;
          // @media only screen and (max-width: $brake4-1) {
          //   margin-top: 4px;
          // }
          @media only screen and (max-width: $brake4-1) {
            margin-top: 4px;
          }
        }
      }
    }
  }
  &.type-17 {
    > .content {
      padding-top: calc(var(--subdiv) * 4);
    }
  }
  &.type-18 {
    > .content {
      flex-wrap: nowrap;
      @media only screen and (max-width: $brake2-4 - 1px) {
        flex-wrap: wrap;
      }

      > .column {
        width: 100%;

        &:not(:first-child) {
          margin-left: calc(var(--baseline) * 4);
          @media only screen and (max-width: $brake2-4 - 1px) {
            margin-left: 0;
            margin-top: calc(var(--baseline) * 1);
          }
        }
        > .title {
          width: 100%;
          @include subtitle-1();
          color: $color-d2;
          user-select: none;
          pointer-events: none;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-bottom: calc(var(--subdiv) * 3);
        }
        > .list {
          width: 100%;

          > li {
            width: 100%;
            display: flex;

            > .counter,
            > .value {
              @include body-text();
            }
            > .counter {
              color: $color-d2;
              user-select: none;
              pointer-events: none;
              margin-right: calc(var(--subdiv) * 3);
            }
            &:not(:first-child) {
              margin-top: calc(var(--subdiv) * 2);
            }
          }
          > .pr-cta-wrapper {
            margin-top: calc(var(--subdiv) * 5);
          }
        }
      }
    }
  }
  &.type-19 {
    > .content {
      padding-top: var(--baseline);
    }
  }
  &.type-20 {
    .subtitle {
      @include subtitle-1;
      color: $color-d2;
    }
    .content {
      flex-direction: column;
      .column {
        width: 100%;
        &:first-child {
          margin-bottom: calc(var(--subdiv) * 4);
        }
      }
      .text {
        width: 100%;
        margin-left: calc(var(--subdiv) * 2);
        > p {
          @include text0;
        }
      }
      .pr-checkbox-1-wrapper.active {
        background: none;
        border: 0;
        box-shadow: none;
        .text p {
          color: $color-d0;
        }
        .accept-ico-wrapper {
          background: $color-f1;
          border: 1px solid $color-f1;
        }
      }
      .pr-checkbox-1-wrapper {
        padding: calc(var(--subdiv) * 2) 0 0;
        border: 0;
        box-shadow: none;
        width: 100%;
        .accept-ico-wrapper {
          border: 1px solid $color-f3;
          $size: 20px;
          min-width: $size;
          min-height: $size;
          .accept-ico {
            $size: 6px;
            width: $size;
            height: $size;
            background: $color-w0;
            border-radius: 100%;
            border: none;
            padding: 0;
            margin: 0;
            display: block;
            transform: none;
          }
        }
      }
    }
  }
  &.type-21 {
    .salaryBlock {
      width: 100%;
    }
    .endpoints-titles {
      display: none;
    }
    .withInputs {
      padding-top: 0;
      .dot {
        > p {
          display: none;
        }
      }
      .heading {
        @include subtitle-1;
        color: $color-d2;
      }
    }
  }
  &.type-22 {
    .pr-dropdown-input-wrapper {
      &.filled {
        .placeholder {
          display: none;
        }
      }
      .placeholder {
        top: unset;
        bottom: calc(var(--baseline) + var(--subdiv) * 3);
      }
      .input-wrapper > input {
        padding: 0 0 calc(var(--subdiv) * 2 + 1px) 0;
      }
      .input-wrapper > input:focus + .placeholder {
        display: none;
      }
    }
    .jobBlock {
      width: 100%;
      .pr-dropdown-input-wrapper {
        margin-bottom: calc(var(--subdiv) * 4);
      }
      .pr-checkbox-2-wrapper {
        margin: calc(var(--subdiv) * 2) 0;
      }
    }
  }
  &.type-23 {
    .pr-counter-wrapper > .counter > {
      .pr-counter-button-wrapper {
        order: 2;
        margin-right: calc(var(--subdiv) * 2);
      }
      .pr-data-input-1-wrapper {
        margin-left: 0;
        margin-right: calc(var(--subdiv) * 2);
      }
    }
  }
  &.type-24 {
    .authorized {
      display: flex;
      align-items: center;
      cursor: pointer;
      > p {
        margin-left: calc(var(--subdiv) * 2);
        @include text-1();
        cursor: pointer;
      }
    }
  }
  &.type-25 {
    .target-date {
      width: 100%;
    }
    .column {
      width: calc(100% / 4 - var(--baseline) / 4 * 3);
      margin-right: var(--baseline);
    }
    .preferred-job {
      margin-top: calc(var(--baseline) * 2 - var(--subdiv) * 4);
      > p {
        margin: var(--baseline) 0;
        @include subtitle-1();
        color: $color-d2;
      }
      > .row {
        display: flex;
      }
    }
    .pr-data-input-2-wrapper {
      max-width: calc(100% / 4 - var(--baseline) / 4 * 3);
    }
  }
  &.type-26 {
    .compensation {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      > * {
        width: calc(50% - var(--baseline) * 2);
      }
      .column {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: var(--subdiv) 0;
        margin-bottom: calc(var(--baseline) / 2);
        > p {
          @include subtitle-1();
          color: $color-d2;
          margin-left: calc(var(--subdiv) * 2);
          cursor: pointer;
        }
      }
    }
  }
  &.type-27 {
    .pr-data-input-2-wrapper {
      max-width: calc(var(--column4) + var(--subdiv) * 3);
      width: 100%;
    }
  }
  &.type-28,
  &.type-29 {
    > .header {
      > .counter {
        display: block;
      }
    }
    > .content {
      .see-more {
        width: 100%;
        margin-top: calc(var(--baseline) + var(--subdiv) * 4);
        display: flex;
        align-items: center;

        &:before,
        &:after {
          content: '';
          height: 1px;
          width: 100%;
          display: block;
          background-color: $color-d4;
        }
        > .pr-cta-wrapper {
          margin: 0 calc(var(--subdiv) * 5);
          flex-shrink: 0;
        }
      }
    }
  }

  @media (pointer: fine) {
    &.type-1 {
      > .content {
        > .column {
          > .dropdown {
            > li {
              &:hover {
                > .pr-checkbox-wrapper {
                  border-color: $color-d2;

                  > .checkmark {
                    > path {
                      stroke: $color-d3;
                    }
                  }
                  &.active {
                    border-color: rgba(255, 255, 255, 0);
                    background-color: $color-f1;

                    > .checkmark {
                      > path {
                        stroke: $color-w0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &.type-10 {
      > .content {
        > .masonry-item {
          &:hover {
            > .pr-checkbox-wrapper {
              border-color: $color-d2;

              > .checkmark {
                > path {
                  stroke: $color-d3;
                }
              }
              &.active {
                border-color: rgba(255, 255, 255, 0);
                background-color: $color-f1;

                > .checkmark {
                  > path {
                    stroke: $color-w0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (min-width: $brake2-4 + 1px) {
    &.type-1,
    &.type-4,
    &.type-5 {
      > .content {
        $count: 4;
        $margin: var(--baseline);

        > * {
          width: calc(100% / #{$count} - #{$margin} / #{$count} * #{$count - 1});

          &:not(:nth-child(#{$count}n)) {
            margin-right: $margin;
          }
        }
      }
    }
  }

  @media only screen and (min-width: $brake2-3 + 1px) {
    &.type-9 {
      > .content {
        @include gallery(2, var(--baseline), calc(var(--baseline) + var(--subdiv) * 4));
      }
    }
    &.type-7 {
      > .content {
        $count: 2;
        $margin: calc(var(--subdiv) * 2);

        > * {
          height: fit-content;
          width: calc(100% / #{$count} - #{$margin} / #{$count} * #{$count - 1});

          &:not(:nth-child(#{$count}n)) {
            margin-right: $margin;
          }
          &:nth-child(#{$count}n):not(:last-child) {
            margin-bottom: $margin;
          }
        }
      }
    }
    &.type-10 {
      > .content {
        columns: 2;
        column-gap: var(--baseline);
      }
    }
    &.type-28 {
      > .content {
        > .gallery {
          @include gallery(3, var(--baseline));
        }
      }
    }
    &.type-29 {
      > .content {
        .gallery {
          @include gallery(4, var(--baseline));
        }
      }
    }
  }

  @media only screen and (max-width: $brake2-1) {
    &.type-10 {
      > .content {
        > .masonry-item {
          > .pr-checkbox-wrapper {
            margin-top: 5px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: $brake2-3) and (min-width: $brake2-4) {
    &.type-3 {
      > .content {
        > .header {
          > .pr-data-input-2-wrapper {
            margin-right: calc(var(--baseline) * 2 + var(--subdiv) * 2);
          }
          > .controls {
            > .options-wrapper {
              margin-left: calc(var(--baseline) * 1 + var(--subdiv) * 5);
            }
          }
        }
      }
    }
    &.type-7 {
      > .content {
        $count: 1;
        $margin: calc(var(--subdiv) * 2);

        > * {
          height: fit-content;
          width: calc(100% / #{$count} - #{$margin} / #{$count} * #{$count - 1});

          &:not(:nth-child(#{$count}n)) {
            margin-right: $margin;
          }
          &:nth-child(#{$count}n):not(:last-child) {
            margin-bottom: $margin;
          }
        }
      }
    }
    &.type-9 {
      > .content {
        @include gallery(1, var(--baseline));
      }
    }
    &.type-28,
    &.type-29 {
      > .content {
        .gallery {
          @include gallery(2, var(--baseline));
        }
      }
    }
  }

  @media only screen and (max-width: $brake2-4) and (min-width: $brake4-1 + 1px) {
    &.type-1,
    &.type-4 {
      > .content {
        $count: 2;
        $margin: var(--baseline);

        > * {
          width: calc(100% / #{$count} - #{$margin} / #{$count} * #{$count - 1});

          &:not(:nth-child(#{$count}n)) {
            margin-right: $margin;
          }
          &:nth-child(#{$count}n):not(:last-child) {
            margin-bottom: calc(var(--subdiv) * 4);
          }
        }
      }
    }
    &.type-3 {
      > .content {
        > .header {
          flex-direction: column;

          > .pr-data-input-2-wrapper {
            margin-right: 0;
          }
          > .controls {
            margin-top: calc(var(--baseline) + var(--subdiv) * 2);
            flex-direction: column-reverse;

            > .pr-counter-wrapper {
              margin-top: calc(var(--baseline) + var(--subdiv) * 2);
            }
            > .options-wrapper {
              margin: 0;

              > .options {
                > .option {
                  > p {
                    line-height: 1.4em;
                    white-space: unset;
                  }
                }
              }
            }
          }
        }
      }
    }
    &.type-5 {
      > .content {
        > .column {
          width: 100%;
        }
        > .column.toggle {
          border-right: none;
          margin-bottom: calc(var(--subdiv) * 5);
        }
        > .column:not(.toggle):not(.date) {
          padding-left: calc(var(--subdiv) * 4);
          margin-bottom: 0;
          border-left: 1px solid $color-d4-a;
          box-sizing: border-box;

          &:nth-child(2) {
            padding-bottom: calc(var(--subdiv) * 3);
          }
        }
        > .column.date {
          margin-top: calc(var(--baseline) + var(--subdiv) * 3);
        }
      }
    }
    &.type-7 {
      > .content {
        $count: 1;
        $margin: calc(var(--subdiv) * 2);

        > * {
          height: fit-content;
          width: calc(100% / #{$count} - #{$margin} / #{$count} * #{$count - 1});

          &:not(:nth-child(#{$count}n)) {
            margin-right: $margin;
          }
          &:nth-child(#{$count}n):not(:last-child) {
            margin-bottom: $margin;
          }
        }
      }
    }
    &.type-9 {
      > .content {
        @include gallery(1, var(--baseline));
      }
    }
    &.type-28,
    &.type-29 {
      > .content {
        .gallery {
          @include gallery(2, var(--baseline));
        }
      }
    }
  }

  @media only screen and (max-width: $brake2-5) {
    &.type-7 {
      > .content {
        $count: 2;
        $margin: calc(var(--subdiv) * 2);

        > * {
          height: fit-content;
          width: calc(100% / #{$count} - #{$margin} / #{$count} * #{$count - 1});

          &:not(:nth-child(#{$count}n)) {
            margin-right: $margin;
          }
          &:nth-child(#{$count}n):not(:last-child) {
            margin-bottom: $margin;
          }
        }
      }
    }
  }

  @media only screen and (max-width: $brake3) {
    &.type-22,
    &.type-23 {
      > .content {
        > li {
          $count: 2;
          $h-spacing: 8%;

          width: calc(100% / #{$count} - #{$h-spacing} * #{$count - 1} / #{$count});

          &:nth-child(#{$count}n):not(:last-child) {
            margin-bottom: calc(var(--baseline) * 2 + var(--subdiv) * 2);
          }
          &:not(:nth-child(#{$count}n)) {
            margin-right: $h-spacing;
            border-right: 1px solid transparent;
            box-sizing: border-box;
          }
        }
      }
    }
  }

  @media only screen and (max-width: $brake4-1) {
    > .header {
      > .indicator {
        position: relative;
        left: 0;
        transform: translateX(0);
        margin-right: calc(var(--subdiv) * 3);
      }
    }
    &.type-1,
    &.type-4 {
      > .content {
        $count: 1;
        $margin: var(--baseline);

        > * {
          width: calc(100% / #{$count} - #{$margin} / #{$count} * #{$count - 1});

          &:not(:nth-child(#{$count}n)) {
            margin-right: $margin;
          }
          &:nth-child(#{$count}n):not(:last-child) {
            margin-bottom: calc(var(--subdiv) * 4);
          }
        }
      }
    }
    &.type-3 {
      > .content {
        > .header {
          flex-direction: column;

          > .pr-data-input-2-wrapper {
            margin-right: 0;
          }
          > .controls {
            margin-top: calc(var(--baseline) + var(--subdiv) * 2);
            flex-direction: column-reverse;

            > .pr-counter-wrapper {
              margin-top: calc(var(--baseline) + var(--subdiv) * 2);
            }
            > .options-wrapper {
              margin: 0;

              > .options {
                > .option {
                  > p {
                    line-height: 1.4em;
                    white-space: unset;
                  }
                }
              }
            }
          }
        }
        > .map-wrapper {
          width: calc(100% + var(--side-margins) * 2);
          margin-left: calc(var(--side-margins) * -1);
        }
      }
    }
    &.type-5 {
      > .content {
        > .column {
          width: 100%;
        }
        > .column.toggle {
          border-right: none;
          margin-bottom: calc(var(--subdiv) * 5);
        }
        > .column:not(.toggle):not(.date) {
          padding-left: calc(var(--subdiv) * 4);
          margin-bottom: 0;
          border-left: 1px solid $color-d4-a;
          box-sizing: border-box;

          &:nth-child(2) {
            padding-bottom: calc(var(--subdiv) * 3);
          }
        }
        > .column.date {
          margin-top: calc(var(--baseline) + var(--subdiv) * 3);
        }
      }
    }
    &.type-7 {
      > .content {
        $count: 2;
        $margin: calc(var(--subdiv) * 2);

        > * {
          height: fit-content;
          width: calc(100% / #{$count} - #{$margin} / #{$count} * #{$count - 1});

          &:not(:nth-child(#{$count}n)) {
            margin-right: $margin;
          }
          &:nth-child(#{$count}n):not(:last-child) {
            margin-bottom: $margin;
          }
        }
      }
    }
    &.type-8 {
      > .header {
        > .counter {
          display: block;
        }
        > .pr-counter-button-wrapper {
          display: flex;
        }
      }
      > .content {
        display: flex;
        flex-direction: column;
        margin-bottom: calc(var(--baseline) + var(--subdiv) * 3);

        > .cards {
          display: flex;
          padding: 0 var(--side-margins);
          padding-top: calc(var(--subdiv) * 3);
          margin-top: calc(var(--subdiv) * -3);
          margin-left: calc(var(--side-margins) * -1);
          flex-shrink: 0;
          padding-bottom: calc(var(--baseline) + var(--subdiv) * 4);
          overflow-y: scroll;
          overscroll-behavior: contain;
          -webkit-overflow-scrolling: touch;

          &::-webkit-scrollbar {
            display: none;
            height: 0;
            width: 0;
          }
          > .pr-experience-card-wrapper {
            flex-shrink: 0;

            &:not(:first-child) {
              margin-top: 0;
              margin-left: calc(var(--subdiv) * 3);
            }
          }
          > .spacer {
            width: var(--side-margins);
            flex-shrink: 0;
          }
        }
        > .indicator {
          width: fit-content;
          max-width: calc(var(--baseline) * 6);
          overflow-y: scroll;
          display: flex;
          margin: auto;
          margin-top: calc(var(--subdiv) * -4);

          > div {
            height: calc(var(--subdiv) * 2);
            width: calc(var(--subdiv) * 2);
            border-radius: 100%;
            background-color: $color-f4-a;
            transition: background-color $time3;
            flex-shrink: 0;

            &:not(:first-child) {
              margin-left: var(--subdiv);
            }
            &.active {
              background-color: $color-f0;
            }
          }
        }
      }
    }
    &.type-9 {
      > .content {
        @include gallery(1, calc(var(--baseline) + var(--subdiv) * 2));

        > .pr-counter-wrapper {
          display: flex;
          flex-direction: column;
        }
      }
    }
    &.type-28,
    &.type-29 {
      > .content {
        .gallery {
          @include gallery(2, var(--baseline));
        }
      }
    }
  }

  @media (pointer: coarse) {
    &.type-1 {
      > .content {
        > .column {
          > .arrow {
            height: 100%;
            width: 100%;
            @include position(absolute, 0, 0, unset, unset);
            opacity: 0;
            pointer-events: none;
            display: block;
            @include transition(opacity, $time4);
            display: flex;
            align-items: center;

            > svg {
              width: calc(var(--subdiv) * 3);
              margin-left: auto;
              margin-right: var(--baseline);
              transform: rotate(0deg);
              @include transition(transform $time4);

              > path {
                stroke: $color-w0;
              }
            }
          }
          &.active {
            > .arrow {
              opacity: 1;
              pointer-events: unset;
            }
            &:hover {
              > .dropdown {
                opacity: 0;
                pointer-events: none;
              }
            }
            &.opened {
              > .arrow {
                > svg {
                  transform: rotate(180deg);
                }
              }
              > .dropdown {
                opacity: 1;
                pointer-events: unset;
              }
            }
          }
        }
      }
    }
  }
}
