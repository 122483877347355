@import 'typography-rythm.scss', 'colors.scss', 'breackpoints.scss', 'typography-scale.scss',
  url('https://fonts.googleapis.com/css?family=Lato:300,400,700');

$font-family1: 'Lato';

@mixin bold {
  -webkit-font-smoothing: subpixel-antialiased;
}
@mixin text-1 {
  @include font($font-family1, 400, var(--font-size1), 0em, 1.42, none);
  @media screen and (max-width: $brake2-1) {
    line-height: 1.384em;
  }
}
@mixin subtitle-0 {
  @include font($font-family1, 700, var(--font-size1), 0.1em, 1.25, uppercase);
  @media screen and (max-width: $brake2-1) {
    line-height: 1.538em;
  }
}
@mixin subtitle-1 {
  @include font($font-family1, 400, var(--font-size0), 0.1em, 1.25, uppercase);
  @media screen and (max-width: $brake2-1) {
    line-height: 1.636em;
  }
}
@mixin text0 {
  @include font($font-family1, 400, var(--font-size2), -0.01em, 1.4, none);
  @media screen and (max-width: $brake2-1) {
    line-height: 1.3em;
  }
}
@mixin text1 {
  @include font($font-family1, 500, var(--font-size4), -0.03em, 1.428, none);
  @media screen and (max-width: $brake2-1) {
    line-height: 1.368em;
  }
}
@mixin text2 {
  @include font($font-family1, 400, var(--font-size5), -0.03em, 1.4, none);
  @media screen and (max-width: $brake2-1) {
    line-height: 1.363em;
  }
}
@mixin text3 {
  @include font($font-family1, 500, var(--font-size6), -0.03em, 1.33, none);
  @media screen and (max-width: $brake2-1) {
    line-height: 1.307em;
  }
}
@mixin text5 {
  @include font($font-family1, 500, var(--font-size9), -0.02em, 1.14, none);
  @include bold();
  @media screen and (max-width: $brake2-1) {
    line-height: 1.307em;
  }
  @media screen and (max-width: $brake2-2) {
    @include font($font-family1, 500, var(--font-size8), -0.02em, 1.307, none);
  }
  @media only screen and (max-width: $brake4), only screen and (max-height: $brake-vertical) {
    @include font($font-family1, 500, var(--font-size6), -0.02em, 1.307, none);
  }
}
@mixin text7 {
  @include font($font-family1, 400, var(--font-size12), -0.01em, 1.13, none);
}
@mixin body-text {
  @include font($font-family1, 400, var(--font-size3), -0.03em, 1.66, none);
  @media screen and (max-width: $brake2-1) {
    line-height: 1.5em;
  }
}
@mixin text8 {
  @include font($font-family1, 500, var(--font-size4), -0.03em, 1.5, none);
}
