@import '../--scss-vars.scss';

$tooltipBg: rgba(255, 255, 255, 0);
$transitionTime: $time3 $ease-in-out-cubic;
$triangleSize: 4px;

$distanceToTooltip: $triangleSize;

[tip] {
  --tooltip-background: #{$color-f4};
  --tooltip-text-color: #{$color-d0};
  position: relative;
}

[tip]:before {
  content: attr(data-description);
  visibility: hidden;
  background: $color-d1;
  z-index: 98;
  opacity: 0;
  transition: $transitionTime;
  position: absolute;
  @include text-1();
  color: $color-w0;
  line-height: 1.5em;
  padding: $triangleSize $triangleSize * 4;
  border-radius: var(--radius2);
  pointer-events: none;
  box-shadow: $darker-sh;
  max-width: calc(var(--baseline) * 12);
  width: fit-content;
}

[tip]:not([tip~='error']):not([tip~='hidden']):hover:before,
[tip]:not([tip~='error']):not([tip~='hidden']).hover:before {
  opacity: 1;
  visibility: visible;
  transition: $time3 $ease-in-out-cubic $time3;
}
[tip]:not([tip~='error']):not([tip~='hidden']):hover:after,
[tip]:not([tip~='error']):not([tip~='hidden']).hover:after {
  opacity: 1;
  visibility: visible;
  transition: $time3 $ease-in-out-cubic $time3;
}

[tip~='top']:before {
  top: -$distanceToTooltip -$triangleSize;
  left: 50%;
  transform: translate(-50%, -100%);
}

[tip~='bottom']:before {
  bottom: -$distanceToTooltip -$triangleSize;
  left: 10%;
  transform: translate(-50%, 100%);
}

[tip~='bottom-left']:before {
  bottom: -$distanceToTooltip -$triangleSize;
  left: 0%;
  transform: translate(-100%, 50%);
}

[tip~='left']:before {
  top: 50%;
  left: -$distanceToTooltip -$triangleSize;
  transform: translate(-100%, -50%);
}

[tip~='right']:before {
  right: -$distanceToTooltip -$triangleSize;
  top: 50%;
  transform: translate(100%, -50%);
}

[tip~='top-left']:before {
  top: -$distanceToTooltip;
  left: 0%;
  transform: translate(calc(#{$triangleSize} * -2), -100%);
}

[tip~='top-right']:before {
  top: -$distanceToTooltip -$triangleSize;
  right: 0%;
  transform: translate(calc(#{$triangleSize} * 2), -100%);
}

[tip~='hoverable']:before {
  pointer-events: unset;
}

[data-description='']:hover:after,
[data-description='']:hover:before {
  visibility: hidden;
}

[tip~='error'] {
  --tooltip-background: #{rgba($color-e0, 0.9)};
  --tooltip-text-color: #{$color-w0};
  &[tip]:before {
    @include text0();
    padding: calc(var(--subdiv) * 2) var(--baseline);
    border-radius: var(--radius3);
    box-shadow: $sh-error-message;
  }
  &[is-error='true'] {
    &[tip]:before,
    &[tip]:after {
      opacity: 1;
      visibility: visible;
      transition: $time3 $ease-in-out-cubic $time3;
    }
  }
}

[tip~='tool-bottom-left']:before {
  bottom: -31px;
  left: 6px;
  transform: translate(-8px, 0);
  font: 400 var(--font-size0) / 1 'Lato', sans-serif, open-sans !important;
  padding: calc(var(--subdiv) * 1.5) calc(var(--subdiv) * 2) !important;
  @media screen and (max-width: $brake2-2) {
    bottom: -27px;
  }
}
[tip~='double-line-tooltip']:before {
  @media screen and (max-width: $brake2-2) {
    bottom: -37px;
  }
}

@media screen and (max-width: $brake4) {
  [tip~='double-line-tooltip']:before {
    bottom: -38px;
  }
}
