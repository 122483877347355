@import '../../../styles/--scss-vars.scss';

.add-attachement {
  .pr-popup-inner {
    > .content.header {
      margin-bottom: var(--gutter);
    }
    ul {
      list-style: none;
    }
  }
  .button-group {
    margin-bottom: var(--gutter);
  }
}
.customReactTable {
  .rt-tbody {
    > .rt-tr-group {
      > .rt-tr {
        > .rt-td {
          div.time {
            .ml-2 {
              margin-left: $subdiv * 1.5;
            }
          }
        }
      }
    }
  }
}
