@import '../../styles/--scss-vars.scss';

.customReactTable {
  border-spacing: 0;
  width: 100%;
  font: 400 var(--font-size0) / 1.25 'Lato', sans-serif, open-sans;
  border-radius: 5px;
  padding: 5px;
  border: none;

  > .rt-table::-webkit-scrollbar {
    width: 0px;
    height: 5px; /* Remove scrollbar space */
    display: block;
  }

  .rt-thead.-filters {
    .pr-data-input-2-wrapper {
      input {
        background: rgba(255, 255, 255, 0);
        border: none;
        border-bottom: 1px solid var(--input-border-default-color);
        border-radius: 0;
        @include body-text();
      }
    }
    .ff-dropdown-input-wrapper {
      padding-top: 0;
      .input-wrapper {
        padding-bottom: 0;
        margin-top: 0;
        input {
          border: 0;
        }
      }
    }
    .ff-dropdown-input-wrapper-group,
    .ff-dropdown-input-wrapper {
      .list {
        li {
          margin: 0;
          width: 100%;
          padding: 0 calc(var(--subdiv) * 3);
        }
      }
    }
  }

  .rt-resizable-header {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    align-items: center;
    display: flex;
  }

  .rt-resizer {
    position: absolute;
    display: flex;
    align-items: center;
    right: 0;
    justify-content: center;

    &:before {
      display: inline-block;
      right: 20px;
      top: 3px;
      height: 18px;
      width: 18px;
      color: transparent;
      content: '';
      background-size: 18px 18px;
      background-repeat: no-repeat;
      opacity: 0.87;
    }
    .rt-resizable-header-content:after {
      height: 18px;
      width: 18px;
      z-index: 120;
      color: transparent;
      content: '.';
    }
  }

  .rt-th.-sort-asc .rt-resizer:before {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTAgMTVWNmw0IDQgMS0xLTYtNi02IDYgMSAxIDQtNHY5eiIgZmlsbD0iIzMzMyIvPjwvc3ZnPg==);
  }

  .rt-th.-sort-desc .rt-resizer:before {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNOCAzdjkuMTNMNCA4IDMgOWw2IDYgNi02LTEtMS00IDQuMTNWM3oiIGZpbGw9IiMzMzMiLz48L3N2Zz4=);
  }

  .rt-thead {
    box-shadow: none !important;
    > .rt-tr {
      > .rt-th {
        // background: #238fd5;
        color: $color-d2;
        font: 400 var(--font-size0) / 1.25 'Lato', sans-serif, open-sans;
        padding: calc(var(--subdiv) * 3) calc(var(--subdiv) * 2);
        text-transform: uppercase;
        letter-spacing: 0.1em;
        text-align: left;
        border: none;
        .ff-dropdown-input-wrapper-group {
          padding-top: 0;
        }
        &:hover,
        &.-sort-asc,
        &.-sort-desc {
          color: $color-d0;
          // padding-right: var(--gutter);
        }
      }
    }
    &.-header {
      border-bottom: 0 !important;
    }
    &:last-child {
      border-bottom: 1px solid $color-f4;
      > .rt-tr {
        .rt-th {
          padding: 0 var(--shift-spacing) calc(var(--subdiv) * 2) var(--shift-spacing);
          border: 0;
        }
      }
    }
  }

  .rt-tbody {
    > .rt-tr-group {
      border: 0;
      > .rt-tr {
        background: transparent;
        align-items: center;
        > .rt-td {
          padding: calc(var(--subdiv) * 3) calc(var(--subdiv) * 2);
          font: 400 var(--font-size2) / 1.9 'Lato', sans-serif, open-sans;
          letter-spacing: -0.03em;
          display: block;
          white-space: nowrap !important;
          text-overflow: ellipsis;
          overflow: hidden !important;
          vertical-align: middle;
          &.tooltip-visible {
            overflow: visible !important;
          }
          &.normal-white-space {
            white-space: normal !important;
          }
          i {
            vertical-align: middle;
            &.fa-check {
              content: url('../../assets/icons/fa-check.svg');
            }
            &.fa-times {
              content: url('../../assets/icons/fa-times.svg');
            }
          }
          img {
            vertical-align: middle;
          }
          position: relative;
          align-items: center;
          border: none;
          // div:not(.table-responsive),
          span,
          a,
          p {
            display: inline;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            &.dot {
              display: inline-block;
            }
          }
          .pr-button-wrapper {
            &.tall {
              width: 100%;
              display: block;
              text-align: center;
            }
          }
        }
        &:hover {
          background: $color-f5-a;
          border-radius: var(--radius5);
        }
      }
    }

    &:last-child {
      td {
        border-bottom: 1px solid $color-f4;
      }
    }
  }
  @mixin arrow {
    position: relative;
    content: '';
    display: inline-block;
    width: 0.7em;
    height: 0.7em;
    border-right: 0.12em solid $color-d0;
    border-top: 0.12em solid $color-d0;
  }

  .pagination-bottom {
    margin-top: var(--baseline);
    .Table__pagination {
      display: flex;
      align-items: center;
      .Table__prevPageWrapper {
        > .Table__pageButton {
          background: transparent;
          border: 0;
          padding-left: calc(var(--subdiv) * 4);
          @include subtitle-1();
          cursor: pointer;
          &:before {
            @include arrow();
            transform: rotate(-135deg) translate(-5%);
            right: calc(var(--subdiv) * 4);
          }
          &:disabled {
            cursor: auto;
            &:before {
              border-right: 0.12em solid $color-d2;
              border-top: 0.12em solid $color-d2;
            }
          }
        }
      }
      .Table__nextPageWrapper {
        > .Table__pageButton {
          background: transparent;
          border: 0;
          padding-right: calc(var(--subdiv) * 4);
          @include subtitle-1();
          cursor: pointer;
          &:after {
            @include arrow();
            left: calc(var(--subdiv) * 4);
            transform: rotate(45deg);
          }
          &:disabled {
            cursor: auto;
            &:after {
              border-right: 0.12em solid $color-d2;
              border-top: 0.12em solid $color-d2;
            }
          }
        }
      }
      .Table__visiblePagesWrapper {
        display: flex;
        align-items: center;
        > .Table__pageButton {
          @include text1();
          padding: 0 12px;
          height: 32px;
          text-align: center;
          margin: auto 4px;
          color: $color-d2;
          display: flex;
          box-sizing: border-box;
          align-items: center;
          letter-spacing: -0.03em;
          border-radius: 19px;
          line-height: 1.43;
          min-width: 32px;
          border: 0;
          background-color: transparent;
          &:hover,
          &[class*='active'] {
            color: $color-d0;
            cursor: pointer;
          }
        }
      }

      &.right {
        justify-content: flex-end;
      }
    }
  }

  &.overflow-filter {
    .rt-table {
      overflow: inherit;
      overflow-y: visible;
      .rt-thead.-filters {
        .rt-th {
          overflow: visible;
        }
      }
    }
  }
  & > .rt-noData {
    top: auto;
    bottom: calc(var(--subdiv) * 4 + 2px);
    padding: calc(var(--subdiv) * 3) calc(var(--subdiv) * 4);
  }
}
