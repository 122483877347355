@import '../../../styles/--scss-vars.scss';

.pr-data-textarea-wrapper {
  height: fit-content;
  position: relative;
  width: 100%;
  &.disable {
    pointer-events: none;
  }
  > .input-wrapper {
    width: 100%;
    padding-top: calc(var(--subdiv) * 5 + 2px);
    border-bottom: 1px solid var(--input-border-default-color);
    @include transition(border-color #{','} color);

    > #textarea {
      // maksymka TODO mb remove to reset
      -webkit-font-smoothing: antialiased;
      //
      // -moz-appearance: textfield;
      // -webkit-appearance: textfield;
      // font: -moz-field;
      // font: -webkit-small-control;
      max-height: 40vh;
      overflow-x: hidden;
      overflow-y: scroll;
      @include body-text();
      line-height: 1.35em !important;
      text-align: left;
      color: $color-d0;
      width: 100%;
      padding-bottom: calc(var(--subdiv) * 2 - 1px);
      background: rgba(255, 255, 255, 0);
      border-radius: 0;
    }
    > span {
      width: 100%;
      opacity: 1;
      top: 0;
      transform: translateY(calc(100% + var(--subdiv) * 2 + 1px));
      @include transition(transform #{','} opacity);
      @include subtitle-1();
      line-height: var(--font-size3);
      color: var(--input-placeholder-span-color);
      display: block;
      position: absolute;
      pointer-events: none;
      user-select: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &:not(.filled) {
    > .input-wrapper {
      > #textarea {
        &:before {
          content: '\feff';
        }
      }
    }
  }
  &.filled {
    > .input-wrapper {
      border-bottom-color: var(--input-border-filled-color);
    }
  }
  @media (pointer: fine) {
    &:hover {
      > .input-wrapper {
        border-bottom-color: var(--input-border-hover-color);
      }
    }
  }
  &.focused {
    > .input-wrapper {
      border-bottom-color: var(--input-border-focus-color);
    }
  }
  &.focused,
  &.filled {
    &:not(.symmetric) {
      > .input-wrapper {
        > span {
          transform: translateY(0px);
        }
      }
    }
  }
  &[is-error='true'] {
    > .input-wrapper {
      border-bottom-color: var(--input-text-error-color);

      > #textarea {
        color: var(--input-border-error-color);
      }
    }
  }
  &.symmetric {
    > .input-wrapper {
      padding-top: calc(var(--subdiv) * 2);

      > #textarea {
        padding-bottom: calc(var(--subdiv) * 2);
      }
    }
    &.focused,
    &.filled {
      > .input-wrapper {
        > span {
          opacity: 0;
        }
      }
    }
  }
  @media screen and (max-width: $brake4) {
    > .input-wrapper {
      padding-top: calc(var(--subdiv) * 7);

      > #textarea {
        padding-bottom: calc(var(--subdiv) * 3);
      }
    }
  }
}
