@import 'typography-styles-mixins.scss';

.bold {
  @include bold();
}
.text-1 {
  @include text-1();
}
.subtitle-1 {
  @include subtitle-1();
}
.subtitle-0 {
  @include subtitle-0();
}
.text0 {
  @include text0();
}
.text1 {
  @include text1();
}
.text2 {
  @include text2();
}
.text3 {
  @include text3();
}
.text5 {
  @include text5();
}
.text7 {
  @include text7();
}
.body-text {
  @include body-text();
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
a,
:before {
  -webkit-font-smoothing: antialiased;
}
// p, li {
//   @include p();
// }
