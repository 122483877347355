@import '../../../styles/--scss-vars.scss';

.ff-textarea-comments {
  --rows-count: 4;
  height: fit-content;
  position: relative;
  width: 100%;
  > label {
    // @include text0();
    font: 400 var(--font-size0) / 1.25 'Lato', sans-serif, open-sans;
    color: var(--input-placeholder-span-color);
    transition-property: transform, opacity;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    transition-delay: 0s;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    display: block;
    position: absolute;
    user-select: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    top: 0;
  }

  > .text {
    min-height: var(--min-height);
    width: 100%;
    // padding: calc(var(--subdiv) * 5 + 2px) 0 calc(var(--subdiv) * 2 + 1px) 0;
    @include body-text();
    color: $color-d0;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: rgba(255, 255, 255, 0);
    border: 1px solid var(--input-border-default-color);
    padding: calc(var(--subdiv) * 2 + 1px);
    border-radius: 5px;
    @include transition(border-color, $time3);
    max-height: 250px;
    overflow-y: scroll;
    position: relative;
    box-sizing: border-box;

    &::placeholder {
      width: 100%;
      line-height: var(--font-size3);
      color: var(--input-placeholder-span-color);
      display: block;
      pointer-events: none;
      -webkit-user-select: none;
      user-select: none;
      @include subtitle-1();
    }
    &.mobile {
      display: none;
    }
    // &:disabled {
    //   border: none;
    // }

    &.marging-top {
      margin-top: calc(var(--subdiv) * 5 + 2px);
    }

    // @media (pointer: fine) {
    //   &:hover {
    //     border-color: $color-d0;
    //   }
    // }
    // @media (pointer: coarse) {
    //   &:focus {
    //     border-color: $color-d0;
    //   }
    // }
  }
  > .pr-cta-wrapper {
    @include transition(opacity, $time4);
    display: none;
  }
  &.has-overflow {
    > .pr-cta-wrapper {
      display: block;
    }
  }
  &.in-edit {
    > .pr-cta-wrapper {
      opacity: 0;
      pointer-events: none;
    }
  }
  // @media only screen and (max-width: $brake4-1) {
  //   > .text {
  //     overflow: unset;
  //     border-color: $color-d4;
  //     padding-bottom: var(--subdiv);

  //     &.mobile {
  //       display: block;
  //       margin-top: 0;
  //       padding-top: calc(var(--subdiv) * 5 + 2px);
  //     }
  //     &:not(.mobile) {
  //       display: none;
  //     }
  //   }
  //   > .pr-cta-wrapper {
  //     display: none !important;
  //   }
  // }
}
