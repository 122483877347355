@import '../../../styles/--scss-vars.scss';

.pr-tooltip-wrapper {
  --shift-spacing: calc(var(--subdiv) * 3);
  --triangle-width: calc(var(--subdiv) * 1.5);
  --triangle-height: var(--subdiv);
  --tooltip-background: #{$color-w0};
  --tooltip-right-spacing: calc(var(--subdiv) * 8);
  --tooltip-left-spacing: calc(var(--subdiv) * 6);

  pointer-events: none;
  opacity: 0;
  @include transition(opacity, $base-time * 2);
  position: absolute;
  > .tooltip-child {
    position: absolute;
    background: var(--tooltip-background);
    box-shadow: 0 0 calc(var(--subdiv) * 5) 0 rgba(55, 98, 186, 0.1);
    padding: calc(var(--subdiv) * 3) var(--tooltip-right-spacing)
      calc(var(--subdiv) * 3) var(--tooltip-left-spacing);
    border-radius: var(--radius6);
  }
  > ul,
  > .tooltip-child {
    position: absolute;
    background: var(--tooltip-background);
    box-shadow: $sh-tooltip;
    padding: calc(var(--subdiv) * 3) var(--tooltip-right-spacing)
      calc(var(--subdiv) * 3) var(--tooltip-left-spacing);
    border-radius: var(--radius6);
    > li {
      position: relative;
      display: flex;
      align-items: center;
      &.active {
        a {
          color: $color-f0;
        }
        svg path {
          stroke: $color-f0;
        }
      }
      &.disabled {
        opacity: 0.5;
        pointer-events: none;
        user-select: none;
      }
      &:before {
        background-color: var(--tooltip-background);
        @include transition(background-color);
        content: '';
        display: block;
        @include position(
          absolute,
          0,
          calc(var(--tooltip-right-spacing) * -1),
          0,
          calc(var(--tooltip-left-spacing) * -1)
        );
        height: 100%;
        width: calc(
          100% + var(--tooltip-right-spacing) + var(--tooltip-left-spacing)
        );
      }
      > * {
        position: relative;
        user-select: none;
      }
      > .ico-wrapper {
        display: flex;
        margin-right: calc(var(--subdiv) * 3);
      }
      > p {
        @include text0();
        padding: calc(var(--subdiv) * 1) 0;

        > a {
          color: $color-f0;
          @include transition(color, $time3);
          cursor: pointer;

          @media (pointer: fine) {
            &:hover {
              color: $color-f0-2;
            }
          }
        }
      }
      // @media (pointer: fine) {
      //   &:hover {
      //     &:not(.description):not(.title) {
      //       cursor: pointer;
      //       &:before {
      //         background-color: $color-d6;
      //       }
      //     }
      //   }
      // }
      &.description,
      &.title {
        width: fit-content;
        display: block;
        pointer-events: none;
      }
      &.title {
        > p {
          @include subtitle-1();
          color: $color-d2;
        }
      }
      &.row {
        display: flex;

        > .title {
          margin-right: calc(var(--subdiv) * 3);
        }
      }
    }
  }
  &.listing {
    > ul,
    > .tooltip-child {
      width: calc(var(--baseline) * 28);
      padding-top: calc(var(--subdiv) * 2);
      left: 0px;
      z-index: 999;
      @media screen and (max-width: $brake4-1 - 1px) {
        box-sizing: border-box;
        width: 100%;
        left: -40px;
        height: calc(var(--baseline) * 18);
      }
      li {
        padding-left: calc(var(--subdiv) * 4);
        margin-bottom: calc(var(--subdiv) * 1);
        &:before {
          width: calc(var(--subdiv) * 1);
          height: calc(var(--subdiv) * 1);
          border-radius: 100%;
          background: $color-w0;
          content: '';
          position: absolute;
          left: 0;
          top: 10px;
        }
        p {
          color: $color-w0;
          font: 400 var(--font-size1) / 1.5 'Lato', sans-serif, open-sans;
        }
        @media screen and (max-width: $brake4-1 - 1px) {
          width: fit-content;
          max-width: 100%;
        }
      }
    }
  }
  &.long {
    > ul,
    > .tooltip-child {
      width: calc(var(--baseline) * 10);
    }
  }
  > .triangle {
    display: none;
    position: absolute;
    border-left: var(--triangle-width) solid rgba(255, 255, 255, 0);
    border-right: var(--triangle-width) solid rgba(255, 255, 255, 0);
    border-top: var(--triangle-height) solid var(--tooltip-background);
  }
  &.with-extra-cta {
    > ul,
    > .tooltip-child {
      > :last-child {
        > p {
          color: $color-e0;
        }
        @media (pointer: fine) {
          &:hover {
            &:before {
              background-color: rgba($color-e0, 0.03);
            }
          }
        }
      }
    }
  }

  &.error {
    > ul,
    > .tooltip-child {
      > li {
        &.title {
          > p {
            color: $color-e0;
          }
        }
      }
    }
  }

  &.blue {
    --tooltip-background: #{$color-f0-1};

    > ul,
    > .tooltip-child {
      p {
        color: $color-w0;

        &.title {
          color: $color-w6-a;
        }
      }
    }
  }
  &.black {
    --tooltip-background: #3b4559;

    > ul,
    > .tooltip-child {
      p {
        color: $color-w0;
        font: 400 var(--font-size1) / 1.5 'Lato', sans-serif, open-sans;
      }
    }
  }
  &.full-width {
    width: 100%;

    > ul,
    > .tooltip-child {
      width: calc(
        100% - var(--tooltip-right-spacing) - var(--tooltip-left-spacing)
      );
    }
  }

  &.top-right {
    top: -1px;
    right: 0;
    height: 1px;
    width: 100%;
    > ul,
    > .tooltip-child {
      bottom: calc(var(--subdiv) * 2);
    }
    &:not(.shift) {
      > ul,
      > .tooltip-child {
        right: 0;
      }
    }
    &.shift {
      > ul,
      > .tooltip-child {
        right: calc(var(--shift-spacing) * -1);
      }
    }
  }

  &.top-left {
    top: -1px;
    left: calc(var(--subdiv) * 4);
    height: 1px;
    width: 100%;

    > ul,
    > .tooltip-child {
      bottom: calc(var(--subdiv) * 2);
    }
  }

  &.bottom-right {
    right: 0;
    height: 1px;
    > ul,
    > .tooltip-child {
      top: calc(var(--subdiv) * 2);
    }
    &:not(.shift) {
      > ul,
      > .tooltip-child {
        right: 0;
      }
    }
    &.shift {
      > ul,
      > .tooltip-child {
        right: calc(var(--shift-spacing) * -1);
      }
    }
  }

  &.bottom-left {
    bottom: -1px;
    left: calc(var(--subdiv) * 4);
    height: 1px;
    width: 100%;

    > ul,
    > .tooltip-child {
      // bottom: calc(var(--subdiv) * 2);
      bottom: auto;
    }
  }

  &.right {
    left: calc(100% + var(--subdiv));
    height: 1px;
    width: 100%;
    top: 15px;
    > ul,
    > .tooltip-child {
      top: calc(var(--subdiv) * 2);
      transform: translateY(-50%);
    }
  }

  &.with-triangle {
    &.top-right {
      > ul,
      > .tooltip-child {
        bottom: calc(var(--triangle-height) * 2);
      }
      > .triangle {
        display: block;
        left: calc(50% - var(--triangle-width));
        bottom: var(--triangle-height);
      }
    }

    &.top-left {
      > ul,
      > .tooltip-child {
        bottom: calc(var(--triangle-height) * 2 + 1px);
      }
      > .triangle {
        display: block;
        left: var(--baseline);
        bottom: var(--triangle-height);
      }
    }

    &.bottom-left {
      > ul,
      > .tooltip-child {
        top: calc(var(--triangle-height) * 2 - 1px);
      }
      > .triangle {
        display: block;
        left: var(--baseline);
        top: var(--triangle-height);
        transform: rotate(180deg);
      }
    }
    &.bottom-right {
      > ul,
      > .tooltip-child {
        top: calc(var(--triangle-width) * 2 - 1px);
      }
      > .triangle {
        display: block;
        right: calc(var(--triangle-width) / 2 + var(--subdiv) * 2);
        top: var(--triangle-height);
        transform: rotate(180deg);
      }
    }
    &.right {
      > ul,
      > .tooltip-child {
        left: calc(var(--triangle-width) - 1px);
      }
      > .triangle {
        display: block;
        left: calc(var(--triangle-width) * -0.5);
        top: calc(50% - var(--triangle-width) / 2);
        transform: rotate(90deg);
      }
    }
  }

  &.large {
    --triangle-height: calc(var(--subdiv) * 2);
    --triangle-width: calc(var(--subdiv) * 2);

    width: calc(var(--baseline) * 9 + var(--subdiv) * 2);
    z-index: 1049;

    > ul,
    > .tooltip-child {
      height: fit-content;
    }
  }

  &.in-view {
    opacity: 1;
    pointer-events: all;
  }
}
.info-desc {
  > ul,
  > .tooltip-child {
    padding: calc(var(--subdiv) * 1);
    padding-left: calc(var(--subdiv) * 3);
    padding-right: calc(var(--subdiv) * 3);
    border-radius: 5px;
    li {
      p {
        font: 400 var(--font-size1) / 1.42 'Lato', sans-serif, open-sans;
        padding: 0px;
      }
    }
  }
}
