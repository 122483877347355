@import '../../../styles/--scss-vars.scss';

.ff-data-animated-input-wrapper {
  height: fit-content;
  position: relative;
  width: 100%;
  &[is-error='true'] {
    margin-bottom: calc(var(--subdiv) * 6);
    > input {
      color: var(--input-border-error-color);
      border-bottom-color: var(--input-text-error-color);
    }
  }
  > input {
    * {
      color: green;
    }
    // maksymka TODO mb remove to reset
    -webkit-font-smoothing: antialiased;
    //
    @include transition(border-color #{','} color);
    @include body-text();
    text-align: left;
    color: $color-d0;
    width: 100%;
    padding: calc(var(--subdiv) * 5 + 2px) 0 calc(var(--subdiv) * 2 + 1px) 0;
    background: rgba(255, 255, 255, 0);
    border-bottom: 1px solid var(--input-border-default-color);
    border-radius: 0;
    &::placeholder {
      color: var(--input-placeholder-color);
    }
    &[type='number'],
    &[type='date'] {
      &::-webkit-inner-spin-button,
      &::-webkit-clear-button {
        display: none;
      }
    }
    &[type='email'] {
      pointer-events: auto;
    }
    &[type='date'] {
      // fix for height size in this uniq input
      // border-top: 2px solid rgba(0,0,0,0);
      padding: calc(var(--subdiv) * 5) 0 calc(var(--subdiv) * 2 - 1px) 0;
      // @media screen and (max-width: $brake2-1) {
      //     border-top: 1px solid rgba(0,0,0,0);
      // }
      cursor: text;
      &::-webkit-calendar-picker-indicator {
        opacity: 0;
        cursor: pointer;
      }
      &::-webkit-datetime-edit-text,
      &::-webkit-datetime-edit-month-field,
      &::-webkit-datetime-edit-day-field,
      &::-webkit-datetime-edit-year-field {
        @include transition(color);
      }
      &::-webkit-datetime-edit-month-field,
      &::-webkit-datetime-edit-day-field,
      &::-webkit-datetime-edit-year-field {
        color: rgba(0, 0, 0, 0);
        &:focus {
          background-color: $color-d0;
          color: $color-w0 !important;
        }
      }
      &::-webkit-datetime-edit-text {
        color: rgba(0, 0, 0, 0);
        padding: 0 var(--subdiv);
      }
    }
  }
  > span {
    width: 100%;
    opacity: 1;
    top: 0;
    transform: translateY(calc(100% + var(--subdiv) * 3 - 1px));
    @include transition(transform #{','} opacity);
    @include subtitle-1();
    line-height: var(--font-size3);
    color: var(--input-placeholder-span-color);
    display: block;
    position: absolute;
    pointer-events: none;
    user-select: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  > .ico-wrapper {
    opacity: 1;
    pointer-events: none;
    margin: auto auto calc(var(--subdiv) * 2) auto;
    @include transition(margin #{','} opacity);
    @include position(absolute, 0, 0, 0, unset);
    display: flex;
    height: calc(var(--subdiv) * 5);
    width: calc(var(--subdiv) * 5);
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    &.left {
      left: 0;
      right: unset;
    }
    &.text {
      @include body-text();
      color: var(--input-placeholder-span-color);
    }
    &.disabled {
      @include body-text();
      color: #888e9a;
    }
    &.after-filling {
      opacity: 0;
      cursor: pointer;
    }
  }
  &.is-ico-true {
    > input:not([type~='date']) {
      padding-right: var(--baseline);
      box-sizing: border-box;
    }
    &.prefix-input {
      > input:not([type~='date']) {
        padding-left: var(--baseline);
        padding-right: 0;
      }
    }
  }
  &.full-width {
    > input {
      width: 100%;
    }
  }
  &.filled {
    > input {
      border-bottom-color: var(--input-border-filled-color);
    }
    > .ico-wrapper {
      margin-bottom: calc(var(--subdiv) * 3);
      &.after-filling {
        opacity: 1;
        pointer-events: all;
        @media (pointer: fine) {
          &:hover {
            opacity: 0.6;
          }
          &:active {
            opacity: 1;
          }
        }
      }
    }
  }
  @media (pointer: fine) {
    &:hover {
      > input {
        border-bottom-color: var(--input-border-hover-color);
      }
    }
  }
  &.focused {
    > input {
      border-bottom-color: var(--input-border-focus-color);
    }
  }
  &.focused,
  &.filled {
    > input {
      &[type='date'] {
        &::-webkit-datetime-edit-month-field,
        &::-webkit-datetime-edit-day-field,
        &::-webkit-datetime-edit-year-field {
          color: rgba($color-d0, 1);
        }
        &::-webkit-datetime-edit-text {
          color: rgba($color-d3, 1);
        }
      }
    }
    &:not(.symmetric) {
      > span {
        transform: translateY(0px);
      }
    }
    > .ico-wrapper {
      margin-bottom: calc(var(--subdiv) * 3 - 3px);
    }
  }
  &.disable {
    // pointer-events: none;
    cursor: not-allowed;
    > input {
      color: $color-d2;
      cursor: not-allowed;
      border-bottom-color: var(--input-border-default-color);
    }
  }
  &.android-device {
    > .ico-wrapper {
      display: none;
    }
  }
  &.symmetric {
    > input {
      padding: calc(var(--subdiv) * 2) 0;
    }
    > span {
      transform: translateY(0px);
      top: unset;
      bottom: calc(var(--subdiv) * 2 + 2px);
    }
    &.focused,
    &.filled {
      > span {
        opacity: 0;
      }
    }
  }
  &.safari {
    > input {
      &[type='date'] {
        padding-top: calc(var(--subdiv) * 6);
        padding-bottom: calc(var(--subdiv) * 2 - 1px);
      }
    }
  }
  &.opera {
    > input {
      &[type='date'] {
        padding-top: calc(var(--subdiv) * 5 + 3px);
        padding-bottom: calc(var(--subdiv) * 2 + 2px);
      }
    }
  }
  &.firefox {
    > input {
      &[type='date'] {
        width: calc(100% - var(--baseline) - var(--subdiv) * 2);
        padding: calc(var(--subdiv) * 5 + 3px) calc(var(--baseline) + var(--subdiv) * 2)
          calc(var(--subdiv) * 3 - 2px) 0;

        & ~ span {
          top: 0;
          transform: translateY(0px);
        }
      }
    }
  }
  @media (pointer: coarse) {
    > input[type='date'] {
      min-height: 1.3em;
    }
    @media only screen and (max-width: $brake4-1) {
      > input[type='date'] {
        min-height: 2em;
      }
    }
  }
  @media screen and (max-width: $brake4) {
    > input {
      -webkit-text-fill-color: black;
      opacity: 1;
      padding-top: calc(var(--subdiv) * 7);
      padding-bottom: calc(var(--subdiv) * 3);
    }
    > .ico-wrapper {
      margin-bottom: calc(var(--subdiv) * 3);
    }
  }
}
