@import './colors.scss';

$skelton-animation-duration: 1.3s;
$skeleton-element-color: rgba(0, 0, 0, 0.13) !default;

$skeleton-circle-size: 80px !default;

$skeleton-line-border-radius: 15px !default;
$skeleton-line-height: 12px !default;
$skeleton-line-margin-bottom: 8px !default;

$skeleton-square-height: 150px !default;

@mixin skelton-animation {
  position: relative;
  overflow: hidden;

  &:after {
    content: '';
    animation: shine-lines $skelton-animation-duration infinite;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(-100%);
    z-index: 1;
    background: linear-gradient(
      90deg,
      transparent,
      hsla(0, 0%, 100%, 0.3),
      transparent
    );
  }
}

@keyframes shine-lines {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}
