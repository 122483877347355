@import '../--scss-vars.scss';

.card {
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: $color-w0;
  flex-direction: column;
  border: none;
  border-radius: var(--radius8);
  padding: calc(var(--baseline) + calc(var(--subdiv) * 2))
    calc(var(--baseline) + calc(var(--subdiv) * 4)) var(--baseline);

  &:not(:last-child) {
    margin: 0 0 calc(var(--subdiv) * 3 + 1px);
  }
  &-title {
    @include text3();
    font: 500 var(--font-size6) / 1.33 'Lato', sans-serif, open-sans;
    letter-spacing: -0.03em;
    text-transform: none;
    min-width: max-content;
  }
  &-title-x {
    font: 500 var(--font-size9) / 1.5 'Lato', sans-serif, open-sans;
    letter-spacing: -0.02em;
    text-transform: none;
    min-width: max-content;
  }
  &-subtitle {
    @include text0();
    font: 400 var(--font-size2) / 1.9 'Lato', sans-serif, open-sans;
    letter-spacing: -0.01em;
    text-transform: none;
    color: $color-d2;
    min-width: max-content;
  }

  .heading {
    margin-bottom: var(--baseline);
  }

  .button-group {
    display: flex;
    justify-content: center;
    > div {
      margin-left: calc(var(--subdiv) * 3);
    }
  }
}
