@import '../--scss-vars.scss';
.grid {
  flex-direction: column;
  display: flex;
}
.row {
  display: flex;
  width: 100%;
  // align-items: center;
  flex-direction: row;

  &.justify-center {
    justify-content: center !important;
  }
  &.space-between {
    justify-content: space-between !important;
  }
  &.space-end {
    justify-content: end !important;
  }
  &.vertical-center {
    align-items: center !important;
  }
  &.vertical-start {
    align-items: flex-start !important;
  }
  &.vertical-end {
    align-items: flex-end !important;
  }
  &.grid {
    flex-direction: column;
    &-12 {
      @include gallery(1, var(--gutter));
    }
    &-6 {
      @include gallery(2, var(--gutter));
    }
    &-4 {
      @include gallery(3, var(--gutter));
    }
    &-3 {
      @include gallery(4, var(--gutter));
    }
    &-2 {
      @include gallery(5, var(--gutter));
    }
  }

  // @for $index from 1 to 6 {
  //   &.col-#{$index} {
  //     width: var(--column#{$index});
  //   }
  // }
}
