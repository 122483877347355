@import '--scss-vars.scss', '--css-vars.scss', '--css-global-styles.scss', '--reset.scss';

@font-face {
  font-family: GucciSansProBold;
  src: url(../assets/fonts/GucciSansPro-Bold.otf);
}

#__bs_script__ {
  display: none;
}
* {
  box-sizing: border-box;
}
*::-webkit-scrollbar {
  width: 0px;
  height: 0px; /* Remove scrollbar space */
  display: none;
  background: transparent; /* Optional: just make scrollbar invisible */
}

html {
  &.no-scroll {
    height: 100%;
    overflow: hidden;
  }
}
body {
  width: 100%;
  background-color: $color-d12;
  &.no-scroll {
    height: 100%;
  }
  #app {
    .page-container {
      transition-property: width;
      transition-duration: 0.9s;
      transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
      transition-delay: 0s;
      width: calc(100% - var(--left-side-bar-width));
      min-height: 100vh;
      margin-left: auto;
      padding: calc(var(--header-height) + calc(var(--subdiv) * 2))
        calc(var(--baseline) + calc(var(--subdiv) * 2)) var(--footer-height) 0;
    }
    > #root {
      height: 100%;
      width: 100%;
      overflow-x: hidden;

      // for presentation
      > .page-wrapper {
        @include transition(margin-top, $base-time * 3);
        margin-top: 0vh;
      }
    }
    &.sidebar-open {
      .page-container {
        width: 100%;
        padding: calc(var(--header-height) + calc(var(--subdiv) * 2))
          calc(var(--baseline) + calc(var(--subdiv) * 2)) var(--footer-height)
          calc(var(--baseline) + calc(var(--subdiv) * 2));
      }
      .left_sidebar {
        width: calc(var(--baseline) + calc(var(--subdiv) * 2));
        .left_sidebar_close {
          background-color: #4a85fb;
        }
        &_inner {
          display: none;
        }
      }
      .page-footer {
        width: 100%;
        max-width: 100%;
      }
    }
    .main-grid {
      height: 100%;
      width: 100%;
      @include position(fixed, 0, unset, unset, 0);
      pointer-events: none;
      display: none;
      background-image: linear-gradient(
          to bottom,
          rgba(red, 0.3) 0.6px,
          rgba(255, 255, 255, 0) 0.6px
        ),
        linear-gradient(to right, rgba(red, 0.3) 0.6px, rgba(255, 255, 255, 0) 0.6px);
      background-repeat: repeat;
      background-size: var(--subdiv) var(--subdiv);
      z-index: 9999;
    }
    .arrow-inline {
      line-height: 1em;
    }
  }
  .mr-20 {
    margin-right: calc(var(--subdiv) * 5);
  }
  .mr-10 {
    margin-right: calc(var(--subdiv) * 2.5);
  }
  .submit-button-group {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    > div {
      padding: calc(var(--subdiv) * 2) calc(var(--subdiv) * 3);
      min-width: calc(var(--column1) * 2 - calc(var(--subdiv)));
      @media screen and (max-width: $brake2-1) {
        padding: calc(var(--subdiv) * 3) calc(var(--subdiv) * 4);
      }
      &:first-child {
        margin-right: calc(var(--subdiv) * 2.5);
      }
    }
  }

  .alert {
    @include text0();
    position: relative;
    padding: calc(var(--subdiv) * 2.5) calc(var(--subdiv) * 3.5);
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    &-warning {
      color: $color-y0;
      background-color: $color-y1;
      border-color: $color-y1;
    }
    &-success {
      color: $color-x0;
      background-color: $color-x4-a;
      border-color: $color-x4-a;
    }
    &-danger {
      color: $color-e1-a;
      background-color: $color-e9-a;
      border-color: $color-e9-a;
    }
    &-info {
      color: $color-a4;
      background-color: $color-a7-a;
      border-color: $color-a7-a;
    }
    &-text {
      background-color: rgba(255, 255, 255, 0);
      border-width: 0;
      padding: 0;
    }
  }

  .checkbox {
    cursor: pointer;
    align-items: flex-start;
    display: flex;
    page-break-inside: avoid;
    break-inside: avoid-column;
    .pr-checkbox-wrapper,
    .pr-radiobutton-wrapper {
      margin-right: calc(var(--subdiv) * 2);
      float: left;
      margin-top: 1px;
    }
    span {
      @include text0();
      color: $color-b1 !important;
      word-break: break-word;
      cursor: pointer;
      float: left;
      user-select: none;
      width: calc(100% - calc(var(--subdiv) * 6));
    }
  }

  .form-group {
    margin-bottom: var(--gutter);
  }
  .tooltip-wrap {
    display: flex;
    position: relative;
    &:hover {
      .pr-tooltip-wrapper {
        opacity: 1;
      }
    }
  }
  .make-link {
    color: $color-f0;
    cursor: pointer;
    user-select: none;
    position: relative;

    &:after {
      position: absolute;
      content: '';
      height: 1px;
      width: 0;
      display: block;
      background-color: $color-f0;
      margin-top: -1px;
      left: 0;
      @include transition(width);
    }
    &:hover,
    &.active {
      color: $color-f0-2;

      &:after {
        background-color: $color-f0-2;
        width: 100%;
      }
    }

    &.dark {
      color: $color-d0;
      &:after {
        background-color: $color-f0;
      }
      &:hover,
      &.active {
        color: $color-f0;
        &:after {
          background-color: $color-f0;
        }
      }
    }
    &.disabled,
    &.disabled:hover {
      color: $color-d2;
      pointer-events: none;
      &:after {
        width: 0;
      }
    }
  }
  .normalText {
    @include text0();
  }
  .submit-button-group {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    > div {
      padding: calc(var(--subdiv) * 2) calc(var(--subdiv) * 3);
      min-width: calc(var(--column1) * 2 - calc(var(--subdiv)));
      @media screen and (max-width: $brake2-1) {
        padding: calc(var(--subdiv) * 3) calc(var(--subdiv) * 4);
      }
      &:first-child {
        margin-right: calc(var(--subdiv) * 2.5);
      }
    }
  }

  .text-center {
    text-align: center;
  }
}
