@import '../../styles/--scss-vars.scss', '../../styles/--css-vars.scss';

.left_sidebar {
  transition-property: width;
  transition-duration: 0.9s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-delay: 0s;
  width: var(--left-side-bar-width);
  box-shadow: none;
  border: none;
  background: transparent;
  padding: calc(var(--header-height) + calc(var(--subdiv) * 2)) 0 calc(var(--subdiv) * 3);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 1049;
  overflow: hidden;
  overflow-y: scroll;

  .left_sidebar_close {
    cursor: pointer;
    padding: calc(var(--subdiv) * 4);
    background-color: #fff;
    position: absolute;
    right: -31px;
    top: calc(var(--header-height) + calc(var(--subdiv) * 9));
    border-radius: 100%;
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
  }

  &_inner {
    > .item {
      padding: calc(var(--subdiv) * 3);
      max-width: calc(var(--baseline) * 5 - 2px);
      width: 100%;
      min-height: calc(var(--column1) + calc(var(--subdiv) * 5));
      margin: 0 auto var(--subdiv) !important;
      border-radius: var(--radius6) !important;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      transition: all 0.3s ease 0s;
      align-items: center;
      justify-content: center;

      img {
        transition: all 0.3s ease 0s;
      }
      .hover-img {
        display: none;
      }
      &::before,
      &:first-child::before {
        display: none !important;
      }
      &:hover,
      &.active {
        background-color: $color-f0;
        box-shadow: 0px 15px 30px rgba(74, 133, 251, 0.3);
        text-decoration: none !important;

        .item-info {
          color: $color-w0;
        }
        .inline {
          display: none;
        }
        .hover-img {
          display: block;
        }
      }

      .item-info {
        font: 500 var(--font-size0) / 1.14 'Lato', sans-serif, open-sans;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: $color-d0;
        width: 100%;
        transition: all 0.3s ease 0s;
        text-align: center;
        margin-top: calc(var(--subdiv) * -3);
        cursor: pointer;
      }
    }
  }
}

.left-bar-popup {
  position: fixed;
  z-index: 1050;
  .triangle {
    display: none !important;
  }

  li {
    &.active,
    &:hover {
      > p,
      * {
        color: $color-f0 !important;
        cursor: pointer;
      }
    }
  }
}
