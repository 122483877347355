@import   '--scss-vars.scss';
        //  'settings/typography-styles.scss'; //?? repeat in --css-global - 'settings/typography-styles.scss';


@media only screen and (min-width: $brake4 + 1px) {
  ::-webkit-scrollbar {
    width: $subdiv;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba($color-d0, .2);
    border-radius: var(--radius3);
  }
}


// maksym added zeroing
*{
  padding: 0;
  margin: 0;
  scrollbar-width: none;
}
*::selection {
  background-color: $color-d0;
  color: $color-w0;
}

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  background: none;
}

body {
  min-height: 100%;
}

theglyph-app {
  display: block;
}

//--reset-component

  *::selection {
    background-color: $color-d0;
    color: $color-w0;
  }
  

  // width: 100%;
  // margin: 0 auto;

  // display: block;
  // position: relative;

  // @include font-smoothing(on);

  :hover, :focus, :active {
    outline: none;
    text-decoration: none;
    // border-style: none;
  }

  /* Hide Play button + controls on iOS */
  video::-webkit-media-controls {
    display:none !important;
  }
  
  img{
    box-sizing: border-box;
  }

  @include placeholder($color-d6);

  ul {
    padding: 0;
  }

  input, textarea, fieldset {
    border: 0;
    outline: none;
    resize: none;
    -ms-overflow-style: none;
    &::-webkit-inner-spin-button {
        display: none;
    }
  }

  a, b, p, h1, h2, h3, h4, h5, h6, ul, span {
    cursor: auto;
    color: $color-d0;
    text-rendering: optimizeLegibility;
  }

  a, li, ul, span, div {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  img {
    @include noselection;
  }

  a, a:visited, a:focus, a:active, a:hover {
    text-decoration: none;
    cursor: pointer;
  }

  li {
    list-style-type: none;
  }