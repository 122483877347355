@import '../../../../../styles/--css-vars.scss', '../../../../../styles/--scss-vars.scss';

.client-details {
  h3 {
    font: 600 var(--font-size1) / 1.14 'Poppins', sans-serif, open-sans;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: $color-d0;
    margin: 0 0 calc(var(--baseline) - 2px);
    &.main-title {
      margin-top: calc(var(--baseline));
    }
  }
  .headtitle {
    font: 500 var(--font-size1) / 1.5 'Lato', sans-serif, open-sans;
    color: $color-d11;
    margin: 0 calc(var(--subdiv) * 2 - 1px) calc(var(--subdiv) * 3 + 1px) 0;
  }
  .headtitle,
  .output_D {
    display: inline-block;
    vertical-align: top;
  }
  .output_D {
    font: 400 var(--font-size1) / 1.5 'Lato', sans-serif, open-sans;
    color: $color-b1 !important;
    word-break: break-word;
    margin-bottom: calc(var(--subdiv) * 3 + 1px);

    a {
      color: $color-f0 !important;
      transition: all 0.3s ease 0s;
    }
    i {
      vertical-align: middle;
      width: $subdiv * 2;
      height: $subdiv * 2;
      display: flex;
      margin: $subdiv 0 0;
      &.fa-check {
        content: url('../../../../../assets/icons/fa-check.svg');
      }
      &.fa-times {
        content: url('../../../../../assets/icons/fa-times.svg');
      }
    }
  }
  .borderLeftRight {
    border-right: 1px solid $color-d4;
    border-left: 1px solid $color-d4;
    padding-left: var(--gutter);
    padding-right: var(--gutter);
    margin-bottom: calc(var(--subdiv) * 5);

    + div {
      padding-left: 0;
    }

    @media screen and (max-width: 991px) {
      border-left: 0;
      padding-left: 15px;
      padding-right: 15px;
      margin-top: calc(var(--subdiv) * 3);

      + div {
        margin-top: calc(var(--subdiv) * 3);
      }
    }
  }
  .borderTop {
    border-top: 1px solid $color-d4;
    padding: var(--baseline) 0 0;
    margin: calc(var(--subdiv) * 3 - 1px) 0 0;
  }
  .border-row-top {
    border-top: 1px solid $color-d4;
    padding: calc(var(--subdiv) * 3) 0 0;
    margin: calc(var(--subdiv) * 2) 0 0;

    > div:first-child {
      padding-left: 0;
    }
  }
}
.client-info {
  > .row {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: inherit;
    -ms-flex-align: inherit;
    align-items: inherit;

    @media screen and (max-width: $brake2-4) {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }

    .divider {
      border-right: 1px solid $color-d4;
      margin: 0 calc(var(--subdiv) * 2);

      @media screen and (max-width: $brake2-4) {
        height: calc(var(--baseline) * 4);
      }
    }
    .client-info-one {
      text-align: center;
      max-width: calc(var(--baseline) * 2 + calc(var(--subdiv) * 4));
      width: 100%;
      margin: 0 calc(var(--subdiv) * 2);
      cursor: pointer;

      @media screen and (max-width: $brake2-4) {
        margin-bottom: calc(var(--subdiv) * 3);
      }

      &.disabled {
        pointer-events: none;
        .client-info-img {
          background-color: $color-d2;
          img {
            &:last-child {
              display: block;
            }
            &:first-child {
              display: none;
            }
          }
        }
      }

      &.active,
      &:hover {
        .client-info-img {
          background-color: $color-f0;

          img {
            &:last-child {
              display: block;
            }
            &:first-child {
              display: none;
            }
          }
        }
        p {
          color: $color-f0;
        }
      }

      .client-info-img {
        width: 50px;
        height: 50px;
        text-align: center;
        background-color: $color-f5-a;
        border-radius: 50%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin: 0 auto;
        cursor: pointer;
        transition: all 0.3s ease 0s;

        img {
          &:last-child {
            display: none;
          }
        }
      }
      p {
        font: 400 var(--font-size1) / 1.5 'Lato', sans-serif, open-sans;
        color: $color-b1;
        margin: calc(var(--subdiv) * 3) 0 0;
        transition: all 0.3s ease 0s;
      }
    }
  }
}
