@import '../../../styles/--scss-vars.scss';

.pr-progress-line-wrapper {
  $maxLoopSize: 15;
  display: none;
  @include position(absolute, 0, unset, unset, 0);
  height: 100%;
  width: var(--subdiv);
  background: $color-f5;
  > span.pr-progress-line {
    @include transition(height);
    display: block;
    position: sticky;
    top: 0;
    width: 100%;
    height: 0vh;
    background: $color-f2-a;
  }
  @for $progressSize from 1 to $maxLoopSize {
    &[progress-size='#{$progressSize}'] {
      @for $progressState from 1 to $maxLoopSize {
        &[progress-state='#{$progressState}'] {
          > span.pr-progress-line {
            height: calc(100vh / #{$progressSize} * #{$progressState});
          }
        }
      }
    }
  }
  @media only screen and (max-width: $brake4),
    only screen and (max-height: $brake-vertical) {
    display: block;
  }
}
