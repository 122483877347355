@import '../../styles/--scss-vars.scss', '../../styles/--css-vars.scss';

#header {
  background-color: $color-bg;
  position: fixed;
  width: 100%;
  z-index: 1050;
  top: 0;
  justify-content: space-between;
  height: var(--header-height);
  padding: 0 var(--side-margins) 0 0;
  .logo {
    // width: var(--left-side-bar-width);
    display: flex;
    justify-content: left;
    align-items: center;
    margin-left: calc(var(--subdiv) * 5.2);
    .black {
      display: flex;
      align-items: center;
      height: 100%;
      max-height: calc(var(--baseline) * 2.2);
    }
    img.headerLogo {
      display: block;
      height: 100%;
      max-height: calc(var(--baseline) * 2.2);
      margin-left: calc(var(--subdiv) * 2);
      max-width: calc(var(--column2) * 1.4);
    }
    .left_sidebar_close {
      cursor: pointer;
    }
  }
  .menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    padding-left: calc(var(--font-base) + 2px);

    .pr-tooltip-wrapper {
      bottom: -14px;
      ul {
        width: calc(var(--baseline) * 7);
        top: calc(var(--triangle-width) * 2 - 5px);
      }
    }

    .notification-bell {
      height: calc(var(--baseline) + var(--subdiv) * 2);
      width: calc(var(--baseline) + var(--subdiv) * 2);
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      // margin-right: calc(var(--subdiv) * 5);
      background-color: transparent;
      box-shadow: none;
      flex-shrink: 0;
      @include transition(background-color #{','} box-shadow, $time3);

      > .icon {
        width: calc(var(--subdiv) * 5);
        position: relative;
        cursor: pointer;

        > .bell {
          width: 100%;
          display: block;
          pointer-events: none;
          @include transition(opacity, $time4);

          > path {
            @include transition(stroke, $time4);

            &:not(.circle) {
              stroke: $color-d2;
            }
            &.circle {
              fill: $color-e0;
            }
          }
          &:not(.active) {
            opacity: 1;
          }
          &.active {
            @include position(absolute, 0, unset, unset, 0);
            opacity: 0;
          }
        }
        @media (pointer: fine) {
          &:hover {
            > .bell {
              > path {
                &:not(.circle) {
                  stroke: $color-d0;
                }
              }
            }
          }
        }
      }
      &.active {
        > .icon {
          > .bell {
            &:not(.active) {
              opacity: 0;
            }
            &.active {
              opacity: 1;
            }
          }
        }
      }

      .rtn-count {
        position: absolute;
        top: 0;
        right: 0;
        background-color: $color-e0;
        min-width: calc(var(--baseline) / 1.4);
        min-height: calc(var(--baseline) / 1.4);
        border-radius: var(--baseline);
        transform: translate(50%, -50%);
        padding: calc(var(--subdiv) / 2) var(--subdiv);
        box-sizing: border-box;
        color: $color-w0;
        @include text3();
        font-size: 10px;
        justify-content: center;
        align-items: center;
        display: flex;
      }
    }

    .bg-blue {
      background-color: $color-f0;
      > .icon {
        > .bell {
          > path {
            &:not(.circle) {
              stroke: $color-w0 !important;
            }
          }
          &:not(.active) {
            opacity: 1;
          }
          &.active {
            opacity: 0;
          }
        }
      }
      > .notifications-wrapper {
        opacity: 1;
        pointer-events: unset;
      }
    }

    &-item {
      display: flex;
      margin: 0 calc(var(--subdiv) * 3);
      align-items: center;
      position: relative;
      cursor: pointer;

      .notifications-wrapper {
        height: fit-content;
        width: calc(var(--baseline) * 11 + var(--subdiv) * 3);
        @include position(
          absolute,
          calc(100% + var(--subdiv) * 5),
          calc(var(--baseline) * -2.2),
          unset,
          unset
        );
        background-color: $color-w0;
        border-radius: var(--radius8);
        box-shadow: $darker-sh;
        opacity: 0;
        pointer-events: none;
        @include transition(opacity, $time3);

        &.opened {
          opacity: 1;
          pointer-events: unset;
        }

        &:before {
          content: '';
          height: 11px;
          width: 11px;
          display: block;
          background-color: $color-w0;
          @include position(
            absolute,
            0,
            calc(var(--baseline) * 2 + var(--subdiv) * 3),
            unset,
            unset
          );
          transform: translateY(-50%) rotate(45deg);
        }
      }
      img {
        max-width: calc(var(--font-base) + 6px);
        height: calc(var(--font-base) + 6px);
        vertical-align: middle;
      }
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      height: calc(var(--baseline) * 2);
      background-color: $color-d2;
      opacity: 0.1;
    }
    .login-user {
      font: 500 var(--font-size3) / 1.4 'Lato', sans-serif;
    }
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 6px solid $color-d0;
    }
    .header-icon {
      cursor: pointer;
      position: relative;
      .user-avatar {
        display: inline-block;
        vertical-align: top;
        position: relative;
        padding-right: calc(var(--subdiv) * 3 + 1px);

        img {
          border-radius: 50%;
          max-width: 100%;
          height: calc(var(--subdiv) * 8);
          width: calc(var(--subdiv) * 8);
          vertical-align: middle;
        }
      }
    }
  }
}
