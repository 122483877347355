@import '../../../styles/--scss-vars.scss';

.pr-drop-area-1-wrapper {
  --dot-color: #{$color-d0};
  --text-pair-spacing: calc(var(--subdiv) * 2);
  $transition-time: $base-time * 2;
  @include transition(
    background #{','} border-color #{','} box-shadow #{','} width,
    $transition-time
  );
  position: relative;
  display: flex;
  width: 100%;
  padding: calc(var(--subdiv) * 4) calc(var(--subdiv) * 4)
    calc(var(--subdiv) * 3) calc(var(--subdiv) * 6);
  //background: $color-f6;
  border: 1px dashed $color-f2;
  border-radius: var(--radius6);
  box-shadow: $sh-card-dark-unset;
  box-sizing: border-box;
  cursor: pointer;
  align-items: center;
  > .aligner {
    @include position(absolute, 0, unset, 0, unset);
    height: fit-content;
    margin: auto 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    > p {
      margin: 0px;
    }
    > span {
      display: none;
    }
  }
  > .mask {
    opacity: 0;
    pointer-events: none;
    user-select: none;
    > p {
      margin-bottom: var(--text-pair-spacing);
    }
  }
  > .aligner,
  > .mask {
    max-width: calc(100% - var(--column1) - var(--gutter));
    > p {
      @include transition(margin-bottom #{','} color);
      @include text1();
      user-select: none;
      pointer-events: none;
      padding-bottom: var(--subdiv);
    }
    > span {
      @include transition(color);
      @include text0();
      width: fit-content;
      max-width: 100%;
      padding-bottom: var(--subdiv);
      color: $color-d2;
      cursor: pointer;
    }
    > p,
    > span {
      @include ellipsis();
      line-height: 1em !important;
      white-space: nowrap;
    }
  }
  > .magic-wrapper {
    display: flex;
    @include position(absolute, 0, 0, 0, 0);
    margin: auto calc(var(--subdiv) * 4) auto auto;
    height: fit-content;
    width: fit-content;
    align-items: center;
    > .placeholder {
      display: none;
      @include body-text();
      white-space: nowrap;
      align-items: center;
      cursor: default;
      > span {
        color: $color-f0;
        @include transition(color);
        position: relative;
        padding: 3px 0;
        cursor: pointer;
        &:before {
          background: $color-f0;
          @include transition(background);
          content: '';
          @include position(absolute, unset, 0, 0, 0);
          height: 1px;
          width: 0;
          pointer-events: none;
          transition: $time3;
        }
        @media (pointer: fine) {
          &:hover {
            color: $color-f1;
            &:before {
              background: $color-f3;
              width: 100%;
            }
          }
        }
      }
    }
    > .cta-wrapper {
      position: relative;
      margin-left: auto;
      > .upload-cta {
        opacity: 1;
        @include transition(opacity);
      }
      > .dots {
        opacity: 0;
        pointer-events: none;
        @include transition(opacity #{','} background, $transition-time);
        @include position(absolute, 0, 0, 0, 0);
        display: flex;
        height: 100%;
        width: 100%;
        background: (0, 0, 0, 0);
        border-radius: 50%;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        > span {
          display: block;
          height: 3px;
          width: 3px;
          background: var(--dot-color);
          border-radius: 50%;
          user-select: none;
          pointer-events: none;
          &:not(:nth-child(3)) {
            margin-right: 4px;
          }
        }
      }
    }
  }
  &.primary {
    --dot-color: #{$color-w0};
    box-shadow: $sh-card-blue-unset;
    cursor: unset;
    > .aligner {
      margin-left: auto;
    }
    > .magic-wrapper {
      > .cta-wrapper {
        margin-right: auto;
        margin-left: calc(var(--subdiv) * 4);
      }
    }
    @media screen and (min-width: $brake4 + 1px) {
      > .aligner {
        display: none;
      }
      > .magic-wrapper {
        margin: auto;
        > .placeholder {
          display: flex;
          user-select: none;
        }
      }
    }
  }
  &.flex {
    > .magic-wrapper {
      width: calc(100% - calc(var(--subdiv) * 8));
      display: flex;
      justify-content: space-between;
      > .cta-wrapper {
        margin-right: 0;
        margin-left: auto;
      }
    }
  }
  &.filled {
    cursor: default;
    &:not(.primary) {
      background: $color-w0;
      border-color: $color-w0;
      box-shadow: $sh-card-dark;
      > .aligner {
        > span {
          @include rolling-line-hover($color-d2);
        }
      }
    }
    &.primary {
      background: $color-f0;
      border-color: $color-f0;
      box-shadow: $sh-card-blue;
      > .aligner {
        display: flex;
        margin-left: 0;
        > p,
        > span {
          color: $color-w0;
        }
        > span {
          @include rolling-line-hover($color-w0);
          color: $color-w8-a;
        }
      }
      > .magic-wrapper {
        > p {
          display: none;
        }
      }
    }
    > .aligner {
      > span {
        display: flex;
      }
      > p {
        margin-bottom: var(--text-pair-spacing);
      }
    }
    > .magic-wrapper {
      margin-right: calc(var(--subdiv) * 4);
      > .cta-wrapper {
        margin-right: 0;
        margin-left: auto;
        > .upload-cta {
          opacity: 0;
          pointer-events: none;
        }
        > .dots {
          pointer-events: all;
        }
      }
    }
    @media (pointer: coarse) {
      > .magic-wrapper {
        > .cta-wrapper {
          > .dots {
            opacity: 1;
          }
        }
      }
    }
    @media (pointer: fine) {
      &:hover {
        > .magic-wrapper {
          > .cta-wrapper {
            > .dots {
              opacity: 1;
            }
          }
        }
      }
    }
    &.active {
      @media screen and (min-width: $brake4-1 + 1px) {
        --dot-color: #{$color-d0};
        background: $color-f5;
        border-color: $color-f5;
        box-shadow: $sh-card-dark-unset;
        > .aligner {
          > p,
          > span {
            color: $color-d0;
          }
        }
        .dots {
          opacity: 1;
        }
      }
    }
  }
  @media (pointer: fine) {
    &:not(.filled) {
      &:hover {
        background: $color-f5-a;
      }
    }
  }
  @media screen and (max-width: $brake4-1) {
    > .magic-wrapper {
      > .cta-wrapper {
        > .dots {
          > :last-child {
            display: none;
          }
        }
      }
    }
  }
}
