@import '../../../styles/--scss-vars.scss';
.progress-wrapper {
  width: 100%;
  top: 0;
  z-index: 1051;
  &.dynamic-progress {
    position: fixed;
  }
  .progress-bar {
    width: 100%;
    background-color: rgba(166, 195, 252, 1);
    border-radius: calc(var(--subdiv) * 1);
    .progress-bar-fill {
      width: 100%;
      display: block;
      height: calc(var(--subdiv) * 1);
      background-color: $color-f0;
      border-radius: calc(var(--subdiv) * 1);
      transition: width 1500ms ease-in-out;
    }
  }
}
