@mixin two-app-in-row-at-panel() {
  $halfs-spacing: calc(var(--subdiv) * 2);
  $first-half: calc((100% - #{$halfs-spacing}) / 2);
  &:first-child {
    width: $first-half;
    margin-right: $halfs-spacing;
  }
  &:last-child {
    width: calc(100% - #{$first-half} - #{$halfs-spacing});
  }
}

@mixin items-align-absolute($wrapper-widht, $padding-top) {
  width: $wrapper-widht;
  margin: 0;
  position: relative;
  overflow: hidden;
  &:before {
    display: block;
    content: '';
    padding-top: $padding-top;
  }
  > img,
  > div,
  > span,
  > p,
  > li {
    position: absolute;
    margin: auto;
    left: 0%;
    top: 0%;
    height: auto;
    width: 100%;
    max-width: initial;
  }
}

@mixin border-bottom-hover-animation {
  &:after {
    @include transition(width);
    content: '';
    display: block;
    border-bottom: 1px solid $color-d0;
    width: 0%;
  }
  &:hover {
    &:after {
      width: 100%;
    }
  }
}

@mixin ios-touch-scroll() {
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin transition(
  $property: all,
  $duration: $base-time,
  $delay: 0s,
  $timing-function: $ease-out-apple1
) {
  transition-property: $property;
  transition-duration: $duration;
  transition-timing-function: $timing-function;
  transition-delay: $delay;
}

@mixin bg-blur-image($URL) {
  position: relative;
  &:before {
    @include position(absolute, 0, 0, 0, 0);
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    filter: blur(50px);
    opacity: 0.3;
    background: url($URL) no-repeat center;
    background-size: 200% !important;
    z-index: 0;
  }
}

@mixin scale-ratio($ratioPers) {
  margin: 0 auto;
  position: relative;
  height: initial;
  &:before {
    content: '';
    display: block;
    padding-top: $ratioPers;
  }
}

//Position
@mixin position($position: null, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}
//Image-Set
@mixin png-image-set($image) {
  @include image-set($image, 'png');
}
@mixin jpg-image-set($image) {
  @include image-set($image, 'jpg');
}
@mixin gif-image-set($image) {
  @include image-set($image, 'gif');
}
@mixin image-set($name, $extension) {
  background-image: url('#{$name}@1x.#{$extension}');
  background-image: -webkit-image-set(
    url('#{$name}@1x.#{$extension}') 1x,
    url('#{$name}@2x.#{$extension}') 2x,
    url('#{$name}@3x.#{$extension}') 3x
  );
  background-image: -moz-image-set(
    url('#{$name}@1x.#{$extension}') 1x,
    url('#{$name}@2x.#{$extension}') 2x,
    url('#{$name}@3x.#{$extension}') 3x
  );
  background-image: -o-image-set(
    url('#{$name}@1x.#{$extension}') 1x,
    url('#{$name}@2x.#{$extension}') 2x,
    url('#{$name}@3x.#{$extension}') 3x
  );
  background-image: -ms-image-set(
    url('#{$name}@1x.#{$extension}') 1x,
    url('#{$name}@2x.#{$extension}') 2x,
    url('#{$name}@3x.#{$extension}') 3x
  );
}

@mixin center-flex() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@mixin rows-ellipsis($row) {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

@mixin border-opacity($size, $color, $opacity) {
  border: $size solid rgba($color, $opacity);
  -webkit-background-clip: padding-box; /* for Safari */
  background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
}

@mixin text-opacity($color, $opacity) {
  color: $color; /* The Fallback */
  color: rgba($color, $opacity);
}

@mixin background-opacity($color, $opacity) {
  background: $color; /* The Fallback */
  background: rgba($color, $opacity);
}

@mixin visible($value: on) {
  @if $value == on {
    visibility: visible;
    opacity: 1;
  } @else {
    opacity: 0;
    visibility: hidden;
  }
}
@mixin noselection() {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently                               supported by Chrome and Opera */
}
@mixin addsvg($name) {
  background: url($name);
  background-repeat: no-repeat;
  background-position: center;
}

@mixin wh($value) {
  width: $value;
  height: $value;
}

@mixin size($width, $height: null) {
  @if ($height == null) {
    $height: $width;
  }

  height: $height;
  width: $width;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin background-size($bg_size) {
  background-size: $bg_size;
  -ms-background-size: $bg_size;
  -o-background-size: $bg_size;
  -moz-background-size: $bg_size;
  -webkit-background-size: $bg_size;
}
@mixin bg-image {
  background-repeat: no-repeat;
  background-size: cover;
  -ms-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-position: 50% 50%;
  box-sizing: border-box;
}

@mixin col-width($col_width) {
  -webkit-column-width: $col_width;
  column-width: $col_width;
}
@mixin col-fill($col_fill) {
  -webkit-column-fill: $col_fill;
  -moz-column-fill: $col_fill;
  column-fill: $col_fill;
}
@mixin col-break($col_break) {
  -webkit-column-break-inside: $col_break;
  -moz-column-break-inside: $col_break;
  // column-break-inside: $col_break;
}

@mixin box-shadow($box_shadow) {
  -moz-box-shadow: $box_shadow;
  -webkit-box-shadow: $box_shadow;
  box-shadow: $box_shadow;
}
@mixin transform($transfofm) {
  -ms-transform: $transfofm;
  -webkit-transform: $transfofm;
  transform: $transfofm;
}

@mixin opacity($op_value, $op_value_ie) {
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$op_value_ie)';
  filter: alpha(opacity=$op_value_ie);
  -moz-opacity: $op_value;
  -khtml-opacity: $op_value;
  opacity: $op_value;
}

// @mixin font-smooth {
//         font-smoothing: antialiased;
//         -moz-font-smoothing: antialiased;
//         -webkit-font-smoothing: antialiased;
// }

@mixin pespective($perspect) {
  -webkit-perspective: $perspect;
  -ms-perspective: $perspect;
  perspective: $perspect;
}
@mixin center-aligment($top, $left) {
  transform: translate3d(0, -50%, 0);
  position: relative;
  top: $top;
  left: $left;
  margin: 0 auto;
}

@mixin box-sizing($parameter) {
  -webkit-box-sizing: $parameter;
  -moz-box-sizing: $parameter;
  box-sizing: $parameter;
}

@mixin placeholder($color) {
  ::-webkit-input-placeholder {
    color: ($color);
  }
  ::-moz-placeholder {
    color: ($color);
  }
  :-ms-input-placeholder {
    color: ($color);
  }
  :-moz-placeholder {
    color: ($color);
  }
}

//Gradient

@function convert-angle($value, $unit) {
  $convertable-units: deg grad turn rad;
  $conversion-factors: 1 (10grad/9deg) (1turn/360deg) (3.1415926rad/180deg);
  @if index($convertable-units, unit($value)) and index($convertable-units, $unit) {
    @return $value / nth($conversion-factors, index($convertable-units, unit($value))) *
      nth($conversion-factors, index($convertable-units, $unit));
  }

  @warn "Cannot convert `#{unit($value)}` to `#{$unit}`.";
}

/// Test if `$value` is an angle
/// @param {*} $value - Value to test
/// @return {Bool}
@function is-direction($value) {
  $is-direction: index(
    (
      to top,
      to top right,
      to right top,
      to right,
      to bottom right,
      to right bottom,
      to bottom,
      to bottom left,
      to left bottom,
      to left,
      to left top,
      to top left
    ),
    $value
  );
  $is-angle: type-of($value) == 'number' and index('deg' 'grad' 'turn' 'rad', unit($value));

  @return $is-direction or $is-angle;
}

/// Convert a direction to legacy syntax
/// @param {Keyword | Angle} $value - Value to convert
/// @require {function} is-direction
/// @require {function} convert-angle
@function legacy-direction($value) {
  @if is-direction($value) == false {
    @warn "Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be an angle or a direction";
  }

  $conversion-map: (
    to top: bottom,
    to top right: bottom left,
    to right top: left bottom,
    to right: left,
    to bottom right: top left,
    to right bottom: left top,
    to bottom: top,
    to bottom left: top right,
    to left bottom: right top,
    to left: right,
    to left top: right bottom,
    to top left: bottom right
  );

  @if map-has-key($conversion-map, $value) {
    @return map-get($conversion-map, $value);
  }

  @return 90deg - convert-angle($value, 'deg');
}

/// Mixin printing a linear-gradient
/// as well as a plain color fallback
/// and the `-webkit-` prefixed declaration
/// @access public
/// @param {String | List | Angle} $direction - Linear gradient direction
/// @param {Arglist} $color-stops - List of color-stops composing the gradient
@mixin linear-gradient($direction, $color-stops...) {
  @if is-direction($direction) == false {
    $color-stops: ($direction, $color-stops);
    $direction: 180deg;
  }

  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}

@mixin black-or-white($value: w) {
  @if $value == w {
    & a,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    ul,
    li,
    p {
      color: $color-w0 !important;
    }
  } @else {
    & a,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    ul,
    li,
    p {
      color: $color-d0;
    }
  }
}

@mixin parallax-element($z: -1) {
  $scale: 1 + (($z * -1) / 1);
  -webkit-transform: translateZ($z + px) scale($scale);
  transform: translateZ($z + px) scale($scale);
}

@mixin gallery($column, $spacing-h, $spacing-v: $spacing-h, $type: 1) {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  > * {
    width: calc(100% / #{$column} - #{$spacing-h} * (#{$column} - 1) / #{$column});
    &:not(:nth-child(#{$column}n)) {
      &:not(:last-child) {
        margin-right: #{$spacing-h};
      }
    }
  }
  // type: $type;
  @if $type == 1 {
    > * {
      height: fit-content;
      &:nth-child(#{$column}n) {
        &:not(:last-child) {
          margin-bottom: #{$spacing-v};
        }
      }
    }
  } @else if $type == 2 {
    margin-bottom: calc(#{$spacing-v} * -1);
    > * {
      margin-bottom: #{$spacing-v};
    }
  }
}

@mixin rolling-line-hover($color: $color-d0, $weight: 1px) {
  position: relative;
  &:after {
    opacity: 0;
    width: 0px;
    @include transition(width #{','} opacity, $base-time * 2);
    position: absolute;
    bottom: 0;
    content: '';
    display: block;
    height: 1px;
    background-color: $color;
  }
  @media (pointer: fine) {
    &:hover {
      &:after {
        opacity: 1;
        width: 100%;
      }
    }
  }
}

@mixin pop-up() {
  height: 100%;
  width: 100%;
  @include position(fixed, 0, 0, 0, 0);
  background: $color-d3-a;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  opacity: 0;
  @include transition(opacity, $time4);
  z-index: 1099;
  pointer-events: none;
  visibility: hidden;

  &.in-view {
    opacity: 1;
    pointer-events: all;
    visibility: visible;
    &.reject-interview {
      .pr-popup-inner {
        .content {
          &.main {
            min-height: calc(var(--baseline) * 8);
          }
        }
      }
    }
    &.comment-timesheet {
      .pr-popup-inner {
        padding-bottom: calc(var(--baseline) + var(--subdiv) * 5);
        .content {
          &.main {
            min-height: calc(var(--baseline) * 8);
            max-height: calc(var(--baseline) * 12);
            overflow-x: hidden;
            overflow-y: scroll;
          }
        }
      }
    }
  }
  > .pr-popup-inner {
    width: calc(100% - var(--side-margins) * 2);
    max-width: calc(var(--baseline) * 24 + var(--subdiv) * 1);
    margin: 0 var(--side-margins);
    background-color: $color-w0;
    border-radius: var(--radius7);
    overflow: hidden;
    position: relative;

    > .content {
      width: calc(100% - var(--baseline) * 3 - var(--subdiv) * 4);
      padding: 0 calc(var(--baseline) + var(--subdiv) * 5);
      box-sizing: content-box;

      &.header {
        padding-top: calc(var(--baseline) + var(--subdiv) * 5);
        border-top-left-radius: var(--radius7);
        border-top-right-radius: var(--radius7);
      }
      &.footer {
        padding-bottom: calc(var(--baseline) + var(--subdiv) * 5);
        border-bottom-left-radius: var(--radius7);
        border-bottom-right-radius: var(--radius7);
      }
    }
    > .pr-x-mark-wrapper {
      height: calc(var(--subdiv) * 5);
      width: calc(var(--subdiv) * 5);
      margin: 0;
      @include position(
        absolute,
        calc(var(--baseline) + var(--subdiv) * 2),
        calc(var(--baseline) + var(--subdiv) * 2),
        unset,
        unset
      );
      cursor: pointer;
      z-index: 2;

      > svg {
        pointer-events: none;

        > path {
          stroke: $color-d0;
        }
      }
    }
  }

  @media only screen and (max-width: $brake4-1) {
    > .pr-popup-inner {
      > .content {
        width: calc(100% - var(--baseline) * 2 - var(--subdiv) * 2);
        padding: 0 calc(var(--baseline) + var(--subdiv));

        &.header {
          padding-top: calc(var(--baseline) + var(--subdiv));
        }
        &.footer {
          padding-bottom: calc(var(--baseline) + var(--subdiv));
        }
      }
      > .pr-x-mark-wrapper {
        height: calc(var(--subdiv) * 5);
        width: calc(var(--subdiv) * 5);
        margin: 0;
        @include position(absolute, var(--baseline), var(--baseline), unset, unset);

        &:before {
          content: '';
          height: 200%;
          width: 200%;
          border-radius: 100%;
          @include position(absolute, -50%, unset, unset, -50%);
        }
      }
    }
  }
}
