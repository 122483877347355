@import '../../styles/--scss-vars.scss', '../../styles/--css-vars.scss';

.notification-content {
  span {
    font: 400 var(--font-size3) / 1.66 'Lato', sans-serif, open-sans !important;
    letter-spacing: -0.03em;
    text-transform: none;
  }
  > .pr-popup-inner > .header.client-user-status-changed {
    height: calc(var(--baseline) * 2.5);
  }
  .custom-content {
    li {
      list-style-type: unset;
      margin-left: 2.5em;
    }
  }
  .content {
    &.main,
    &.normaltext {
      a {
        text-decoration: none !important;
        color: #4a85fb !important;
        position: relative;
        display: inline-block;
        user-select: inherit !important;
        pointer-events: visible !important;

        > * {
          cursor: pointer;
          color: #4a85fb !important;
        }

        &::after {
          position: absolute;
          content: '';
          height: 1px;
          width: 0;
          display: block;
          background-color: #4a85fb;
          margin-top: -1px;
          transition-property: width;
          transition-duration: 0.3s;
          transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
          transition-delay: 0s;
        }
        &:hover:after {
          background-color: #3762ba;
          width: 100%;
        }
      }
    }
    &.normaltext {
      height: fit-content;
      max-height: calc(100vh - var(--side-margins) * 2 - var(--baseline) * 9);
      padding-top: calc(var(--baseline) * 2.5);
      padding-bottom: calc(var(--baseline) * 4);
      overflow-y: scroll;
      overflow-x: hidden;
    }
  }
}

.notifications-list-wrapper {
  height: fit-content;
  width: 100%;
  max-height: calc(100vh - var(--header-height) - var(--baseline) * 3);
  border-radius: var(--radius8);
  overflow-x: hidden;
  overflow-y: scroll;

  > .title {
    @include subtitle-1();
    color: $color-d2-a;
    padding: 0 calc(var(--baseline) + var(--subdiv));
    padding-top: var(--baseline);
    padding-bottom: calc(var(--subdiv) * 2);
    @include position(sticky, 0, unset, unset, 0);
    background: $color-w3-a;
    backdrop-filter: blur($blur4);
    user-select: none;
    pointer-events: none;
    border-top-left-radius: var(--radius8);
    z-index: 1;
  }
  > li {
    width: calc(100% - var(--baseline) * 2 - var(--subdiv) * 2);
    margin: 0 calc(var(--baseline) + var(--subdiv));
    padding-top: calc(var(--subdiv) * 3);
    padding-bottom: calc(var(--subdiv) * 4);
    position: relative;
    cursor: pointer;

    &:not(:last-of-type) {
      border-bottom: 1px solid $color-d5;
    }
    &:last-of-type {
      margin-bottom: var(--baseline);
    }
    &.message {
      @include subtitle-1();
      text-align: center;
      margin-bottom: 0;
    }
    &.loading-inner {
      text-align: center;
      margin-bottom: 0;
    }

    &:before {
      content: '';
      height: 100%;
      width: calc(100% + var(--baseline) * 2 + var(--subdiv) * 2);
      display: block;
      @include position(absolute, 0, unset, unset, calc(var(--baseline) * -1 - var(--subdiv)));
      background-color: transparent;
      @include transition(background-color, $time3);
    }
    > .title {
      position: relative;
      margin-bottom: calc(var(--subdiv) * 2);

      > .indicator {
        height: 6px;
        width: 6px;
        border-radius: 100%;
        background-color: $color-e0;
        @include position(absolute, calc(50% - 3px), calc(100% + var(--subdiv) * 2), unset, unset);
        pointer-events: none;
        opacity: 0;
        @include transition(opacity, $time3);

        &.active {
          opacity: 1;
        }
      }
      > p {
        @include text0();
        user-select: none;
        pointer-events: none;
      }
    }
    > .description {
      @include text-1();
      margin-bottom: calc(var(--subdiv) * 3);
      user-select: none;
      pointer-events: none;
    }
    > .date {
      display: flex;
      justify-content: space-between;

      > p {
        @include subtitle-1();
        color: $color-d2-a;
        user-select: none;
        pointer-events: none;
      }
    }
    @media (pointer: fine) {
      &:hover,
      &.unread {
        &:before {
          background-color: $color-d5-a;
        }
      }
    }
  }
  &::-webkit-scrollbar {
    display: block;
    width: 3px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $color-d4;
    padding-right: var(--subdiv);
  }
  &::-webkit-scrollbar-track-piece:start {
    margin-top: calc(var(--baseline) * 2);
  }
  &::-webkit-scrollbar-track-piece:end {
    margin-bottom: var(--baseline);
  }

  &:not(.chrome):not(.safari):not(.opera):not(.crios) {
    > .title {
      background-color: $color-w1-a;
    }
  }

  @media only screen and (max-width: $brake4-1) {
    max-height: 100%;
    > .title {
      display: none;
    }
    > li {
      &:first-of-type {
        margin-top: var(--header-height);
      }
    }
  }
}
