@import '../../../styles/--scss-vars.scss';

.Notifications-details {
  @include text0();
  .content {
    &.main {
      hr {
        margin: calc(var(--subdiv) * 3) 0;
      }
      .info-header {
        .grid-6:last-child {
          .grid:first-child {
            width: calc(var(--gutter) * 2.5);
          }
          .grid:last-child {
            width: calc(100% - calc(var(--gutter) * 3.5));
            .dateTime {
              margin-top: calc(var(--subdiv) * 3);
            }
          }
          .reciever-text {
            word-break: break-all;
          }
        }
      }
      .row {
        &.space-between {
          margin: calc(var(--subdiv) * 3) 0 var(--subdiv);
        }
        .make-link {
          word-break: break-all;
        }
      }
      .notes-content {
        padding: calc(var(--subdiv) * 3) 0 0;
        word-break: break-all;
        > div {
          width: 100%;
          &:first-child {
            width: calc(100% / 4 - var(--gutter) * (2 - 1) / 2);
            min-width: calc(100% / 4 - var(--gutter) * (2 - 1) / 2);
            margin-right: calc(var(--gutter) - 5px);
          }
        }
        p {
          a,
          a span {
            color: $color-f0;
          }
        }
      }
    }
  }
}
