@import '../../../styles/--scss-vars.scss';

.pr-ico-calendar-wrapper {
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  > svg {
    position: absolute;
  }
  &:before {
    content: '';
    display: block;
    padding-top: 115%;
  }
}
