@import '../../../styles/--scss-vars.scss';

.message-wrapper {
  --x-size: calc(var(--subdiv) * 5 + 1px);
  opacity: 1;
  // pointer-events: none;
  @include transition(opacity, $base-time * 2);
  width: 100%;

  > .content {
    //@include position(absolute, unset, 0, unset, 0);
    display: flex;
    width: fit-content;
    padding: calc(var(--subdiv) * 2) 0;
    margin: auto;
    background: rgba($color-e0, 0.9);
    border-radius: var(--radius3);
    align-items: center;
    box-shadow: $sh-error-message;
    > p {
      @include text0();
      margin: 0 calc(var(--baseline)) 0
        calc(var(--baseline) + var(--subdiv) * 3);
      color: $color-w0;
      cursor: default;
    }
    > .divider {
      display: block;
      height: 1.4em;
      width: 1px;
      background: rgba($color-w0, 0.2);
    }
    > .x-mark-wrapper {
      height: var(--baseline);
      width: var(--baseline);
      margin: 0 calc(var(--subdiv) * 2);
      cursor: pointer;
      > .x-mark {
        opacity: 0.5;
      }
      @media (pointer: fine) {
        &:hover {
          > .x-mark {
            opacity: 1;
          }
        }
      }
    }
  }
  &.success {
    > .content {
      background: rgba($color-x2-a, 0.9);
    }
  }
  &.full {
    max-width: 100%;
    margin: calc(var(--baseline) * 0.2) 0;
    > .content {
      width: 100%;
      position: relative;
      > p {
        margin: 0 0 0 calc(var(--baseline) * 0.5);
      }
      > .divider {
        display: none;
      }
      > .x-mark-wrapper {
        position: absolute;
        right: 0;
      }
    }
  }
  @media screen and (max-width: $brake3) {
    &.half {
      max-width: calc(100vw - var(--side-margins) * 2);
    }
  }
}
