@import '../../../styles/--scss-vars.scss';

.Note-details {
  @include text0();
  & > .pr-popup-inner {
    max-width: var(--column10);
  }
  .content {
    &.header {
      height: calc(var(--baseline) * 3) !important;
    }
    &.main {
      padding: calc(var(--baseline) * 2.5) calc(var(--baseline) * 2) calc(var(--baseline) * 2) !important;
      .row {
        &.grid-6 {
          margin-bottom: var(--subdiv);
          .note-data {
            word-break: break-all;
          }
        }
      }
      hr {
        margin: calc(var(--subdiv) * 3) 0;
      }
      .notes-content {
        padding: calc(var(--subdiv) * 3) 0 0;
        word-break: break-all;
        > div {
          width: 100%;
          &:first-child {
            width: calc(100% / 4 - var(--gutter) * (2 - 1) / 2);
            min-width: calc(100% / 4 - var(--gutter) * (2 - 1) / 2);
            margin-right: calc(var(--gutter) - 5px);
          }
        }
        p {
          a,
          a span {
            color: $color-f0;
          }
        }
        &.notes-link {
          a {
            color: $color-f0;
          }
          img + span.make-link {
            white-space: nowrap;
          }
        }
      }

      .comments {
        display: flex;
        justify-content: space-between;
      }

      .actionBtn {
        margin-top: calc(var(--subdiv) * 2);
        .pr-button-wrapper {
          margin-left: var(--subdiv);
        }
      }
      .list {
        list-style: none;
        margin-top: calc(var(--subdiv) * 2);
        padding: 0;
        li {
          margin: 0;
          margin-bottom: calc(var(--subdiv) * 3);
        }
      }
    }
  }
}

.MentionUI {
  > h4 {
    margin-bottom: var(--subdiv);
  }
  .wrapperClassName {
    .rdw-suggestion-dropdown {
      border-radius: 20px;
      padding: calc(var(--subdiv) * 3) calc(var(--subdiv) * 2);
      transition-property: opacity;
      transition-duration: 0.4s;
      transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
      transition-delay: 0s;
      z-index: 100;
      overflow-y: scroll;
      background: #fff;
      box-shadow: 0 var(--subdiv) calc(var(--subdiv) * 5) var(--subdiv) rgba(1, 14, 40, 0.04);
      > span {
        font: 400 var(--font-size3) / 1.66 Lato, sans-serif, open-sans;
        min-height: calc(var(--baseline) + var(--subdiv) * 5);
        width: 100%;
        min-width: var(--column2);
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;
        padding: calc(var(--subdiv) * 2) calc(var(--subdiv) * 4);
        border-radius: 10px;
        background-color: rgba(255, 255, 255, 0);
        transition-property: background-color;
        transition-duration: 0.4s;
        transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
        transition-delay: 0s;
        margin: 0 auto;
        border: 0;
        &:hover,
        &:focus {
          background-color: $color-f5-a;
        }
      }
    }
  }
}
