@import '../../../styles/--scss-vars.scss';

.pr-img-icon-wrapper{
    > img {
        width: fit-content;
        display: block;
        user-select: none;
        pointer-events: none;
    }
    &.default{
        width: fit-content;
    }
    &.top-shifted{
        margin-top: 2px;
    }
    &.wrapped-to-circle{
        @include transition(background-color, $time4);
        position: relative;
        display: flex;
        height: fit-content;
        flex-shrink: 0;
        width: calc(var(--baseline) + var(--subdiv) * 3 + 1px);
        background-color: $color-f5;
        border-radius: 100%;
        justify-content: center;
        align-items: center;
        align-self: center;
        cursor: pointer;
        > img {
            position: absolute;
        }
        &:before {
            content: '';
            display: block;
            padding-top: 100%;
        }
        &:not(.clickable){
            pointer-events: none;
        }
        &.clickable{
            @media (pointer: fine) {
                &:hover {
                    background-color: $color-f4;
                }
            }
        }
    }
    &.right-spacing{
        margin-right: calc(var(--subdiv) * 3);
    }
    &.type-2{
        &:not(.double){
            width: calc(var(--baseline) + var(--subdiv) * 4 + 1px);
        }
        &.double{
            width: calc(var(--baseline) + var(--subdiv) * 4);
        }
    }
    @media only screen and (max-width: $brake4-1) and (min-width: $brake5 + 1px) {
        &.clickable{
            width: calc(var(--baseline) + var(--subdiv) * 5 + 1px);
        }
        &.large{
            width: calc(var(--baseline) + var(--subdiv) * 6 + 1px);
        }
    }
}