@import '../../../styles/--scss-vars';
.error-popup {
  z-index: 9999;

  .left-align {
    text-align: left;
  }
  .invoice-error {
    .scrollable {
      height: fit-content;
      max-height: calc(70vh - var(--side-margins) * 2 - var(--baseline) * 9);
      overflow-y: scroll;
      overflow-x: hidden;
    }
  }

  .main {
    .title {
      user-select: inherit !important;
      pointer-events: visible !important;
      a {
        color: rgb(14, 111, 190);
        font: 500 var(--font-size6) / 1.33 'Lato', sans-serif, open-sans;
        word-break: break-all;
        position: relative;
        display: inline-block;
        &::after {
          position: absolute;
          content: '';
          height: 1px;
          width: 0;
          display: block;
          background-color: #4a85fb;
          margin-top: -1px;
          transition-property: width;
          transition-duration: 0.3s;
          transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
          transition-delay: 0s;
        }
      }
      .sub-title {
        font: 400 var(--font-size3) / 1.45 'Lato', sans-serif, open-sans;
        text-align: center;
        margin-top: calc(var(--subdiv) * 3);
      }
    }
  }
}
