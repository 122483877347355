@import '../../../styles/--scss-vars.scss';

.pr-info-button-wrapper {
  height: calc(var(--subdiv) * 6);
  width: calc(var(--subdiv) * 6);
  background-color: $color-d5-a;
  @include transition(background-color, $time3);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  > svg {
    width: calc(var(--subdiv) * 2);
  > path {
      stroke: $color-d0;
    }
  }
  &.small {
    height: calc(var(--subdiv) * 5);
    width: calc(var(--subdiv) * 5);
    > svg {
      width: calc(var(--subdiv) * 1.75);
    }
  }
  &.xs {
    height: calc(var(--subdiv) * 4);
    width: calc(var(--subdiv) * 4);
  }
  &.blue {
    background-color: $color-f5-a;

    > svg {
      > path {
        stroke: $color-f0;
      }
    }
  }
  &.red {
    background-color: $color-e8-a;
    > svg {
      > path {
        stroke: $color-e1-a;
      }
    }
  }
  @media (pointer: fine) {
    &:hover {
      background-color: $color-d4-a;
    }
    &:active {
      background-color: $color-d5-a;
    }
    &.blue {
      &:hover {
        background-color: $color-f4-a;
      }
      &:active {
        background-color: $color-f5-a;
      }
    }
    &.red {
      &:hover {
        background-color: $color-e8-a;
      }
      &:active {
        background-color: $color-e8-a;
      }
    }
  }
}
