@import '../../../styles/--scss-vars.scss';

.pr-button-wrapper {
  height: fit-content;
  position: relative;
  @include transition(background-color #{','} box-shadow #{','} border-color, $base-time * 2);
  @include text0();
  background-color: var(--submit-background);
  border-radius: var(--radius4);
  box-shadow: var(--submit-box-shadow);
  padding: var(--subdiv) calc(var(--subdiv) * 3);
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;

  > img {
    display: block;
    user-select: none;
    pointer-events: none;
    margin-right: calc(var(--subdiv) * 2);
  }
  > p {
    @include transition(color, $base-time * 2);
    text-align: center;
    color: var(--submit-color);
    white-space: nowrap;
    user-select: none;
    pointer-events: none;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 var(--subdiv);
  }
  > .dot {
    width: calc(var(--subdiv) * 2);
    height: calc(var(--subdiv) * 2);
    border-radius: 100px;
    margin: 0 var(--subdiv);
  }
  &.loading {
    display: flex;
    pointer-events: none;
    align-items: center;
    justify-content: center;
    padding: calc(var(--subdiv) * 1.5) calc(var(--subdiv) * 4);

    @media screen and (min-width: $brake2-1) {
      &.tall {
        svg {
          max-height: calc(var(--subdiv) * 4.6);
        }
      }
      &.small {
        svg {
          max-height: calc(var(--subdiv) * 4.4);
        }
      }
    }
    // &.medium,
    &.large {
      svg {
        max-height: calc(var(--subdiv) * 5);
      }
    }
  }
  &.tall {
    padding: calc(var(--subdiv) * 2) calc(var(--subdiv) * 3);
  }
  &.large {
    padding: calc(var(--subdiv) + 2.32px) calc(var(--subdiv) * 3);
    min-width: calc(var(--column1) * 2 + calc(var(--subdiv) * 2));
  }
  &.medium {
    padding: calc(var(--subdiv) + 2.32px) calc(var(--subdiv) * 3);
    min-width: calc(var(--column1) * 2 - calc(var(--subdiv)));
  }
  &.small {
    padding: calc(var(--subdiv) + 2.32px) calc(var(--subdiv) * 1);
    min-width: calc(var(--column1) * 2 - calc(var(--subdiv) * 15));
    height: calc(var(--subdiv) * 3.8);
  }
  &:not(.full-width) {
    width: fit-content;
  }
  &.full-width {
    width: calc(100% - var(--subdiv) * 6);
  }
  &.white {
    --submit-background: #{$color-f5-a};
    --submit-color: #{$color-f0};
    --submit-box-shadow: #{$sh-pop-up-submit-empty};
    --submit-hover-background: #{$color-f4-a};
    --submit-active-background: #{$color-f3-a};
  }
  &.red {
    --submit-background: #{$color-e1-a};
    --submit-color: #{$color-w0};
    --submit-box-shadow: #{$sh-pop-up-submit-empty};
    --submit-hover-background: #{$color-e1};
    --submit-active-background: #{$color-e1};
  }
  &.border-btn {
    --submit-background: #{$color-w0};
    --submit-color: #{$color-f0};
    --submit-box-shadow: #{$sh-pop-up-submit-empty};
    --submit-hover-background: #{$color-f4-a};
    --submit-active-background: #{$color-f3-a};
    border: 1px solid $color-f4-a;
  }
  &.blue {
    --submit-background: #{$color-f0};
    --submit-color: #{$color-f5};
    --submit-box-shadow: #{$sh-pop-up-submit-accept};
    --submit-hover-background: #{$color-f1};
    --submit-active-background: #{$color-f2};
  }
  &.light-blue {
    --submit-background: #{$color-f5-a};
    --submit-color: #{$color-f0};
    --submit-box-shadow: none;
    --submit-hover-background: #{$color-f0};
    --submit-hover-color: #{$color-f5};
    --submit-active-color: #{$color-f5};
    --submit-active-background: #{$color-f2};
  }
  &.light-red {
    --submit-background: #{$color-e5-a};
    --submit-color: #{$color-e0};
    --submit-box-shadow: none;
    --submit-hover-background: #{$color-e4-a};
    --submit-active-background: #{$color-e3-a};
  }
  &.blue-border {
    --submit-background: #{$color-f3-a};
    --submit-color: #{$color-f0};
    --submit-hover-background: #{$color-f0-1};
    --submit-hover-color: #{$color-f0-1};
    --submit-active-background: #{$color-f1-a};
    --submit-active-color: #{$color-f1-a};

    padding-left: calc(var(--subdiv) * 3);
    padding-right: calc(var(--subdiv) * 3);
    margin: -1px 0;
    border: 1px solid var(--submit-background);
    background-color: transparent;
  }
  &.withIcon {
    align-items: center;
    justify-content: space-between;
    padding: var(--subdiv) calc(var(--subdiv) * 3);
    &:active,
    &:focus {
      background-color: $color-f5-a;
      border-color: $color-w0;
    }
    &.blue-border {
      &:active {
        border-color: transparent;
      }
    }
    &.active {
      border-color: transparent;
      background-color: $color-f5-a;
      &:hover {
        border-color: transparent;
      }
    }
    > p {
      @include text0;
      padding-right: var(--subdiv);
    }
    .tooltip-wrap {
      display: flex;
      position: relative;
      &:hover {
        .pr-tooltip-wrapper {
          opacity: 1;
        }
      }
    }
  }
  &.disable {
    --submit-background: #{$color-d4};
    --submit-box-shadow: #{$sh-pop-up-submit-empty};
    pointer-events: none;
    user-select: none;

    > p {
      color: $color-d2;
    }
    &:hover {
      .tooltip-wrap {
        > .pr-tooltip-wrapper {
          opacity: 1;
        }
      }
    }
  }
  @media (pointer: fine) {
    &:not(.blue-border) {
      &:hover {
        background: var(--submit-hover-background);
      }
      &:active {
        background: var(--submit-active-background);
      }
    }
    &.blue-border,
    &.light-blue {
      &:hover {
        border-color: var(--submit-hover-background);
        > p {
          color: var(--submit-hover-color);
        }
      }
      &:active {
        border-color: var(--submit-active-background);
        > p {
          color: var(--submit-active-color);
        }
      }
    }
    &.border-btn {
      &:hover {
        border-color: var(--submit-hover-background);
        background-color: $color-f0;
        > img {
          filter: brightness(100);
        }
        > p {
          color: $color-w0;
        }
      }
      &:active {
        border-color: var(--submit-active-background);
        > img {
          filter: brightness(100);
        }
        > p {
          color: var(--submit-active-color);
        }
      }
    }
  }
  @media screen and (max-width: $brake2-1) {
    padding: calc(var(--subdiv) * 1.5) calc(var(--subdiv) * 4);

    &.tall {
      padding: calc(var(--subdiv) * 3) calc(var(--subdiv) * 4);
    }
    &.small {
      padding: calc(var(--subdiv) + 2.32px) calc(var(--subdiv) * 1);
      min-width: calc(var(--column1) * 2 - calc(var(--subdiv) * 15));
      height: calc(var(--subdiv) * 5);
    }
    &.full-width {
      width: calc(100% - var(--subdiv) * 8);
    }
    // &.border-btn {
    //   padding: calc(var(--subdiv) * 2.75) var(--subdiv);
    // }
    &.loading {
      padding: calc(var(--subdiv) * 1.5) calc(var(--subdiv) * 4);
    }
  }
  &:hover {
    .pr-info-button-wrapper {
      background-color: $color-d4-a;
      svg {
        path {
          stroke: #fff;
        }
      }
    }
    > .pr-tooltip-wrapper {
      opacity: 1;
    }
  }
}
