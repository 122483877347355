@import '../../../styles/--scss-vars.scss';

.pr-ico-eye-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  > svg {
    position: absolute;
  }
  &:before {
    content: '';
    display: block;
    padding-top: 125%;
  }
}
