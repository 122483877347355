@import '../../../styles/--scss-vars.scss';

.ff-multi-email-wrapper {
  height: fit-content;
  display: flex;
  @include transition(border-color #{','} color);
  border-bottom: 1px solid var(--input-border-default-color);
  border-radius: 0;
  @include body-text();
  -webkit-font-smoothing: antialiased;
  position: relative;
  flex-wrap: wrap;
  padding-top: calc(var(--subdiv) * 5);
  align-items: center;
  align-content: flex-start;

  > p {
    margin: calc(var(--subdiv) * 0.5) calc(var(--subdiv) * 3) calc(var(--subdiv) * 0.5) 0;
    display: flex;
    align-items: center;
    background-color: $color-f5-a;
    border-radius: var(--radius3);
    padding: calc(var(--subdiv) * 0.5) calc(var(--subdiv) * 2);
    color: $color-f0;
    word-break: break-all;
    &.disabled {
      span {
        pointer-events: none;
      }
    }

    span {
      margin-left: calc(var(--subdiv) * 2);
      color: $color-f0;
      font: 600 var(--font-size5) / 1.25 'Lato', sans-serif, open-sans;
      cursor: pointer;
    }
  }

  .input-wrapper {
    height: fit-content;
    position: initial;
    width: 100%;
    > input {
      * {
        color: green;
      }
      // maksymka TODO mb remove to reset
      -webkit-font-smoothing: antialiased;
      //
      @include body-text();
      // line-height: 1em !important;
      text-align: left;
      color: $color-d0;
      // width: 100%;
      // padding: calc(var(--subdiv) * 5 + 2px) 0 calc(var(--subdiv) * 3 + 1px) 0;
      background: rgba(255, 255, 255, 0);
      width: 100%;
      outline: none !important;
      border: 0 none !important;
      display: inline-block !important;
      vertical-align: baseline !important;
      padding: 0.4em 0.1em !important;

      &::placeholder {
        color: var(--input-placeholder-color);
      }
    }
    > span {
      width: 100%;
      opacity: 1;
      top: 0;
      left: 0;
      transform: translateY(calc(100% + var(--subdiv) * 3 - 1px));
      @include transition(transform #{','} opacity);
      @include subtitle-1();
      line-height: var(--font-size3);
      color: var(--input-placeholder-span-color);
      display: block;
      position: absolute;
      pointer-events: none;
      user-select: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &.filled {
      > input {
        border-bottom-color: var(--input-border-filled-color);
      }
    }
    @media (pointer: fine) {
      &:hover {
        > input {
          border-bottom-color: var(--input-border-hover-color);
        }
      }
    }
    &.focused {
      > input {
        border-bottom-color: var(--input-border-focus-color);
      }
    }
    &.focused,
    &.filled {
      &:not(.symmetric) {
        > span {
          transform: translateY(0px);
        }
      }
    }
    &[is-error='true'] {
      > input {
        color: var(--input-border-error-color);
        border-bottom-color: var(--input-text-error-color);
      }
    }
    &.disable {
      cursor: not-allowed;
      > input {
        color: $color-d2;
        cursor: not-allowed;
      }
    }
    &.symmetric {
      > input {
        padding: calc(var(--subdiv) * 2) 0;
      }
      > span {
        transform: translateY(0px);
        top: unset;
        bottom: calc(var(--subdiv) * 2 + 2px);
      }
      &.focused,
      &.filled {
        > span {
          opacity: 0;
        }
      }
    }
    @media screen and (max-width: $brake4) {
      > input {
        padding-top: calc(var(--subdiv) * 7);
        padding-bottom: calc(var(--subdiv) * 3);
      }
    }
  }
  &.disabled {
    cursor: not-allowed;
    > p {
      cursor: not-allowed;
    }
    > .input-wrapper {
      > input {
        cursor: not-allowed;
      }
    }
  }
}
