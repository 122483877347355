@import '../../styles/--scss-vars.scss';
.error-outer {
  display: flex;
  align-items: center;
  position: relative;
  .error-page {
    display: flex;
    max-width: var(--column12);
    align-items: center;
    margin: 0 auto;
    position: relative;
    min-height: 70vh;
    .error-info {
      width: 100%;
      text-align: center;
      height: 100%;
      h2 {
        font: 900 var(--font-size16) / 1 'Lato', sans-serif, open-sans;
        margin-bottom: calc(var(--subdiv) * 6);
        color: $color-f0;
        letter-spacing: 0.1em;
      }
      @media screen and (max-width: $brake4-1) {
        flex-direction: column;
      }
      .title {
        font: 500 var(--font-size9) / 1.428 'Lato', sans-serif, open-sans;
        letter-spacing: -0.03em;
        color: $color-d0;
      }
      .desc-text {
        font: 400 var(--font-size6) / 1.9 'Lato', sans-serif, open-sans;
        color: $color-d10;
        letter-spacing: -0.01em;
        margin-top: calc(var(--subdiv) * 3);
      }
      .pr-button-wrapper {
        margin: calc(var(--subdiv) * 6) auto 0;
        padding: calc(var(--subdiv) * 3) calc(var(--subdiv) * 6);
      }

      @media screen and (max-width: $brake4-1 - 1) {
        h2 {
          font: 900 var(--font-size13) / 1 'Lato', sans-serif, open-sans;
        }
        .title {
          font: 500 var(--font-size4) / 1.428 'Lato', sans-serif, open-sans;
        }
        .desc-text {
          font: 400 var(--font-size3) / 1.428 'Lato', sans-serif, open-sans;
        }
      }
    }
  }
  .corona {
    @include position(absolute, unset, unset, 0, 0);
    height: 35vw;
    width: 35vw;
    transform: translate(-40%, 50%);
    > .pr-animated-logo-2-wrapper {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      transition-property: transform;
      svg {
        bottom: 0;
        height: 100%;
        > g {
          > path {
            stroke: $color-d2;
          }
        }
      }
    }
  }
}
