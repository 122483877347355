@import '../../../styles/--scss-vars.scss';

.html-editor-wrapper {
  width: 100%;
  border: 1px solid var(--input-border-default-color);
  border-radius: var(--radius6);
  box-sizing: border-box;
  padding: calc(var(--subdiv) * 3 + 1px);

  .html-editor-wysiwyg {
    .rdw-dropdown-wrapper:hover {
      box-shadow: none;
      background: transparent;
    }
    .rdw-link-decorator-wrapper {
      a {
        span {
          color: #1589d2 !important;
          cursor: pointer;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    .rdw-dropdown-optionwrapper {
      > li {
        @include text0();
        margin: 0 !important;
      }
    }
    .rdw-dropdown-selectedtext {
      border: 1px solid rgb(204, 204, 204);
      text-decoration: none;
      color: #333 !important;
      min-width: 50px;
      > span {
        @include text0();
      }

      .rdw-dropdown-carettoopen {
        top: 38%;
      }
    }
    .details {
      .public-DraftEditorPlaceholder-inner {
        width: 100%;
        opacity: 1;
        @include subtitle-1();
        line-height: var(--font-size3);
        color: var(--input-placeholder-span-color);
        display: block;
        pointer-events: none;
        user-select: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .public-DraftStyleDefault-listLTR {
      margin-left: 2.5em;
    }

    .rdw-editor-main {
      height: calc(var(--baseline) * 8);
    }
    .public-DraftEditor-content {
      .public-DraftStyleDefault-ltr {
        * {
          color: inherit;
        }
      }
    }

    @media screen and (max-width: $brake4-1) {
      .rdw-dropdown-selectedtext {
        min-width: 31px;
      }
      .rdw-dropdown-wrapper {
        width: auto;
        &.rdw-fontfamily-dropdown {
          width: calc(var(--baseline) * 3);
        }
      }
    }
  }
}
