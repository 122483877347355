@import '../../../../styles/--scss-vars.scss';

#edit-notification {
  .htmlEditor {
    .editorClassName {
      font-size: 12px;
      font-family: Helvetica, 'Arial', Verdana, Tahoma, sans-serif;
    }
  }
  label {
    display: flex;
    align-items: center;
    @include subtitle-1();
    color: var(--input-placeholder-span-color);
    margin-bottom: calc(var(--baseline) * 0.5);
  }
  .checkbox {
    span {
      @include text0();
    }
  }
  .tooltip-wrap {
    margin-left: calc(var(--subdiv) * 2);
    position: relative;
    &:hover {
      .pr-tooltip-wrapper {
        opacity: 1;
      }
    }
  }
  .pr-expand-collapse > .content {
    margin-bottom: 0;
  }
  .html-editor-wysiwyg {
    @include body-text();
  }
  .pill {
    @include subtitle-1();
    background: #4a85fb;
    color: #fff;
    border-radius: var(--radius8);
    padding: var(--subdiv);
    margin-left: calc(var(--baseline) * 0.5);
  }
  .affix {
    position: fixed;
    top: calc(var(--header-height) + calc(var(--baseline) * 4));
    min-width: var(--column6);
    right: var(--column2);
    @media screen and (max-width: $brake2-2) {
      right: var(--column1);
    }
  }
  .options {
    margin-top: calc(var(--subdiv) * 3);
    flex-direction: column;
    border: none;
    border-radius: var(--radius8);
    padding: calc(var(--baseline)) calc(var(--baseline)) var(--baseline);
    height: calc(var(--baseline) * 8);
    &.view {
      background-color: #f1f1f1;
    }
    &.overflow-scroll {
      overflow-y: scroll;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        padding: 10px;
        width: 5px;
        display: block;
      }
      @-moz-document url-prefix() {
        padding-top: 0;
        padding-bottom: 0;
      }
      scrollbar-width: thin;
      scrollbar-color: #e9ecef white;
    }
    li {
      margin-bottom: calc(var(--subdiv) * 2);
      @include text0();
    }
  }
  .notification-grid {
    > div:last-child {
      margin-left: var(--column1);
    }
  }
  .form-group {
    margin-bottom: var(--baseline) !important;
  }
  .ff-data-animated-input-wrapper,
  .ff-multi-email-wrapper,
  .full-width {
    width: 100%;
  }
}

.notification-list {
  .rt-thead.-filters {
    input {
      border: none;
    }
  }
  .rt-th {
    overflow: visible;
  }
}
