// OPACITY
$opacity0: 0.95;
$opacity1: 0.82609;
$opacity2: 0.71834;
$opacity3: 0.62464;
$opacity4: 0.54317;
$opacity5: 0.47232;
$opacity6: 0.41071;
$opacity7: 0.35714;
$opacity8: 0.31056;
$opacity9: 0.13502;

// BLUR
$blur1: 5px;
$blur2: 10px;
$blur3: 15px;
$blur4: 20px;
$blur5: 25px;
$blur6: 30px;

// WHITE
$color-w0: #fff;
$color-w1-a: rgba(255, 255, 255, 0.97);
$color-w2-a: rgba(255, 255, 255, 0.94);
$color-w3-a: rgba(255, 255, 255, 0.91);
$color-w4-a: rgba(255, 255, 255, 0.87);
$color-w5-a: rgba(255, 255, 255, 0.83);
$color-w6-a: rgba(255, 255, 255, 0.78);
$color-w7-a: rgba(255, 255, 255, 0.71);
$color-w8-a: rgba(255, 255, 255, 0.62);
$color-w9-a: rgba(255, 255, 255, 0.48);
$color-w10-a: rgba(255, 255, 255, 0.27);
$color-w11-a: rgba(253, 253, 254, 1);
$color-w12-a: rgba(255, 255, 255, 0.09);

// DARK
$color-b0: #000;
$color-b1: #000103;
$color-d0: rgba(1, 14, 40, 1);
$color-d1: rgba(59, 69, 89, 1);
$color-d1-a: rgba(1, 14, 40, 0.77);
$color-d1-b: rgba(1, 14, 40, 0.6);
$color-d2: rgba(136, 142, 154, 1);
$color-d2-a: rgba(1, 14, 40, 0.8);
$color-d3: rgba(204, 207, 212, 1);
$color-d3-a: rgba(1, 14, 40, 0.2);
$color-d3-1: rgba(204, 207, 212, 0.5);
$color-d3-b: rgba(1, 14, 40, 0.05);
$color-d4: rgba(232, 233, 236, 1);
$color-d4-a: rgba(1, 14, 40, 0.09);
$color-d5: rgba(245, 245, 246, 1);
$color-d5-a: rgba(1, 14, 40, 0.04);
$color-d6: rgba(250, 250, 251, 1);
$color-d6-a: rgba(1, 14, 40, 0.02);
$color-d0-1: rgba(1, 9, 25, 1);
$color-d0-2: rgba(0, 6, 17, 1);
$color-d0-3: rgba(255, 88, 101, 0.04);
$color-d7: rgba(189, 189, 189, 1);
$color-d7-a: rgba(189, 189, 189, 0.1);
$color-d8: rgba(47, 128, 237, 1);
$color-d8-a: rgba(47, 128, 237, 0.06);
$color-d9: rgba(0, 0, 0, 0.05);
$color-d10: rgba(196, 196, 196, 1);
$color-d11: rgba(148, 154, 165, 1);
$color-d12: rgba(245, 247, 252, 1.5);
$color-d9-a: rgba(30, 29, 64, 0.02);
$color-d10-a: rgba(30, 29, 64, 0);

// BLUE
$color-f0: rgba(74, 133, 251, 1);
$color-f1: rgba(101, 151, 252, 1);
$color-f1-a: rgba(74, 133, 251, 0.85);
$color-f2: rgba(152, 185, 253, 1);
$color-f2-a: rgba(74, 133, 251, 0.57);
$color-f3: rgba(197, 216, 254, 1);
$color-f3-a: rgba(74, 133, 251, 0.32);
$color-f4: rgba(222, 233, 254, 1);
$color-f4-a: rgba(74, 133, 251, 0.18);
$color-f5: rgba(239, 244, 255, 1);
$color-f5-a: rgba(74, 133, 251, 0.09);
$color-f6: rgba(250, 251, 255, 1);
$color-f6-a: rgba(74, 133, 251, 0.03);
$color-f0-1: rgba(67, 121, 228, 1);
$color-f0-2: rgba(55, 98, 186, 1);
$color-f0-3: rgba(21, 60, 140, 1);
$color-f0-3-a: rgba(21, 60, 140, 0.9);
$color-f0-4: rgba(45, 79, 151, 1);
$color-f0-5: rgba(214, 226, 255, 1);
$color-f0-6: rgba(247, 250, 255, 1);

// RED
$color-e0: rgba(227, 41, 86, 1);
$color-e0-4: #821130;
$color-e0-3: #ae1740;
$color-e0-2: #c31a48;
$color-e0-1: #ce1c4c;
$color-e1: #db2859;
$color-e2: #dd3462;
$color-e3: #e14a73;
$color-e4: #e87796;
$color-e5: #f0a5b9;
$color-e6: #f7d2dc;
$color-e7: #fbe8ee;
$color-e8: #fdf4f6;
$color-e9: #fefafc;
$color-e1-a: rgba(227, 41, 86, 0.85);
$color-e2-a: rgba(227, 41, 86, 0.57);
$color-e3-a: rgba(227, 41, 86, 0.32);
$color-e4-a: rgba(227, 41, 86, 0.18);
$color-e5-a: rgba(227, 41, 86, 0.09);
$color-e6-a: rgba(227, 41, 86, 0.03);
$color-e7-a: rgba(255, 88, 101, 0.8);
$color-e8-a: rgba(255, 156, 164, 0.8);
$color-e9: rgba(255, 88, 101, 1);
$color-e9-a: rgba(255, 88, 101, 0.09);
$color-e10-a: rgba(217, 29, 80, 0);

// x
$color-x0: rgba(15, 221, 122, 1);
$color-x1: rgba(80, 230, 158, 1);
$color-x1-a: rgba(15, 221, 122, 0.73);
$color-x2: rgba(137, 238, 190, 1);
$color-x2-a: rgba(15, 221, 122, 0.49);
$color-x3: rgba(178, 244, 212, 1);
$color-x3-a: rgba(15, 221, 122, 0.32);
$color-x4: rgba(207, 248, 228, 1);
$color-x4-a: rgba(15, 221, 122, 0.2);
$color-x5: rgba(226, 251, 239, 1);
$color-x5-a: rgba(15, 221, 122, 0.12);
$color-x6: rgba(243, 253, 248, 1);
$color-x6-a: rgba(15, 221, 122, 0.05);
$color-x0-1: rgba(9, 139, 77, 1);
$color-x0-2: rgba(6, 95, 52, 1);
$color-x7: rgb(255, 244, 79);

// BG color
$color-bg: #f5f7fc;
$color-card-visited: #eef2f9;

// A
$color-a0: #0055ff;
$color-a0-4: #003399;
$color-a0-3: #0044cc;
$color-a0-2: #004de6;
$color-a0-1: #0051f2;
$color-a1: #0d5eff;
$color-a2: #1a66ff;
$color-a3: #3377ff;
$color-a4: #6699ff;
$color-a5: #99bbff;
$color-a6: #ccddff;
$color-a7: #e6eeff;
$color-a8: #f2f7ff;
$color-a9: #fafcff;
$color-a1-a: rgba(0, 85, 255, 0.95);
$color-a2-a: rgba(0, 85, 255, 0.9);
$color-a3-a: rgba(0, 85, 255, 0.8);
$color-a4-a: rgba(0, 85, 255, 0.6);
$color-a5-a: rgba(0, 85, 255, 0.4);
$color-a6-a: rgba(0, 85, 255, 0.2);
$color-a7-a: rgba(0, 85, 255, 0.1);
$color-a8-a: rgba(0, 85, 255, 0.05);
$color-a9-a: rgba(0, 85, 255, 0.02);
$color-a10-a: rgba(0, 85, 255, 0);

// alert
$color-y0: rgb(133, 100, 4);
$color-y1: rgb(255, 243, 205);
