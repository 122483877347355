@import '../../../styles/--scss-vars.scss';

.pr-checkbox-wrapper {
  height: calc(var(--subdiv) * 4);
  width: calc(var(--subdiv) * 4);
  border-radius: var(--radius2);
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid $color-d3;
  display: flex;
  justify-content: center;
  align-items: center;
  @include transition(border-color #{','} background-color, $time4);

  > .checkmark {
    width: 50%;

    > path {
      stroke: rgba(255, 255, 255, 0);
      @include transition(stroke, $time4);
    }
  }
  &.active {
    border-color: rgba(255, 255, 255, 0);
    background-color: $color-f0;

    > .checkmark {
      > path {
        stroke: $color-w0;
      }
    }
  }

  &.disable {
    opacity: 0.5;
    pointer-events: none;
    background-color: $color-d3;
    &.active {
      > .checkmark {
        > path {
          stroke: $color-d0;
        }
      }
    }
  }

  @media (pointer: fine) {
    &:hover {
      border-color: $color-d2;

      > .checkmark {
        > path {
          stroke: $color-d3;
        }
      }
    }
    &.active {
      &:hover {
        border-color: rgba(233, 220, 220, 0);
        background-color: $color-f1;

        > .checkmark {
          > path {
            stroke: $color-w0;
          }
        }
      }
    }
  }

  @media only screen and (max-width: $brake4-1) {
    height: calc(var(--subdiv) * 5 + 1px);
    width: calc(var(--subdiv) * 5 + 1px);
    > .checkmark {
      width: calc(var(--subdiv) * 2 + 1px);
    }
  }
}
